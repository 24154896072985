import {
    SET_CURRENT_PROPOSAL,
    LOAD_PROPOSALS,
    UPDATE_PROPOSAL,
    CLEAR_CURRENT_PROPOSAL,
    BULK_REJECT_PROPOSAL,
    LOAD_PROPOSALS_STATS,
} from "../types";

const initialState = {
    proposals: [],
    proposalsStats: [],
    pageInfo: {},
    filteredProposals: null,
    currentProposal: null,
};

const transFormProposalStats = (stats) => {
    let results;

    if (stats.length > 0) {
        console.log(stats);
        const { Applicants, Accepted, InReview } = stats[0];

        results = [
            {
                name: "Applicants",
                count: parseInt(Applicants),
            },
            {
                name: "Accepted",
                count: parseInt(Accepted),
            },
            { name: "InReview", count: parseInt(InReview) },
        ];
    } else {
        results = [
            {
                name: "Applicants",
                count: 0,
            },
            {
                name: "Accepted",
                count: 0,
            },
            { name: "InReview", count: 0 },
        ];
    }

    return results;
};

/* eslint-disable import/no-anonymous-default-export */
export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_PROPOSALS:
            return {
                ...state,
                proposals: action.payload.results,
                pageInfo: action.payload.pageInfo,
            };
        case LOAD_PROPOSALS_STATS:
            return {
                ...state,
                proposalsStats: transFormProposalStats(action.payload),
            };
        case UPDATE_PROPOSAL:
            // overwriting field for previous obj since the intial data is not consistent with returning updated data
            return {
                ...state,
                proposals: state.proposals.map((proposal) => {
                    return proposal.id === action.payload.id
                        ? { ...proposal, ...action.payload }
                        : proposal;
                }),
            };
        case BULK_REJECT_PROPOSAL:
            const removedIds = action.payload;
            return {
                ...state,
                proposals: [
                    ...state.proposals.filter((proposal) => {
                        return !removedIds.includes(proposal.id);
                    }),
                ],
            };
        case SET_CURRENT_PROPOSAL:
            return {
                ...state,
                currentProposal: action.payload,
            };
        case CLEAR_CURRENT_PROPOSAL:
            return {
                ...state,
                currentProposal: null,
            };

        default:
            return state;
    }
}
