import {
  LOAD_MORE_QWIB_MERCHANT_COMMISSIONS,
  LOAD_QWIB_MERCHANT_COMMISSIONS,
  SET_COMMISSION_TRIPS_RIDERS,
  SET_QWIB_ORDERS,
  SET_QWIB_SALES,
  SET_QWIB_STATS,
  SET_RIDER_STATS,
  LOAD_MORE_HISTORY_ORDERS,
  LOAD_HISTORY_ORDERS,
} from "../types";

const initialState = {
  history: {
    loading: true,
    pageInfo: null,
    orders: null,
  },
  stats: {
    totalOrders: null,
    totalTrips: null,
    commission: null,
    loading: true,
  },
  riderStats: {
    totalOrders: null,
    totalTrips: null,
    commission: null,
    loading: true,
  },
  ordersStats: {
    orders: [
      {
        day: new Date().toISOString(),
        count: 0,
      },
    ],
    loading: true,
  },
  salesStats: {
    sales: [
      {
        day: new Date().toISOString(),
        total_income: 0,
      },
    ],
    loading: true,
  },
  merchantsCommissions: {
    results: null,
    pageInfo: null,
    loading: true,
  },
  ridersCommissions: {
    results: null,
    pageInfo: null,
    loading: true,
  },
  errors: null,
  severity: null,
  successMessage: null,
  loadingData: false,
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case SET_QWIB_STATS:
      return {
        ...state,
        stats: {
          ...action.payload,
          loading: false,
        },
      };
    case LOAD_HISTORY_ORDERS:
      return {
        ...state,
        history: {
          orders: action.payload.results,
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    case LOAD_MORE_HISTORY_ORDERS:
      return {
        ...state,
        history: {
          orders: [...state.history.orders, ...action.payload.results],
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    case SET_RIDER_STATS:
      return {
        ...state,
        riderStats: {
          ...action.payload,
          loading: false,
        },
      };

    case SET_QWIB_ORDERS:
      return {
        ...state,
        ordersStats: {
          orders: action.payload,
          loading: false,
        },
      };

    case SET_QWIB_SALES:
      return {
        ...state,
        salesStats: {
          sales: action.payload,
          loading: false,
        },
      };

    case LOAD_QWIB_MERCHANT_COMMISSIONS:
      return {
        ...state,
        merchantsCommissions: {
          results: action.payload.results,
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    case LOAD_MORE_QWIB_MERCHANT_COMMISSIONS:
      return {
        ...state,
        merchantsCommissions: {
          results: [
            ...state.merchantsCommissions.results,
            ...action.payload.results,
          ],
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    case SET_COMMISSION_TRIPS_RIDERS:
      return {
        ...state,
        ridersCommissions: {
          results: action.payload.results,
          pageInfo: action.payload.pageInfo,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default reports;
