import {
  ADD_TO_BASKET,
  CLEAR_BASKET,
  LOADING_UI,
  REMOVE_FROM_BASKET,
  SET_ERRORS,
  SET_SUCCESS,
  UN_ASSIGNED_SELECTED,
  ADD_MULTIPLE_TO_BASKET,
} from "../types";
import { sendReceiverSms, sendRiderSms } from "./smsActions";

import axios from "axios";
import { generateOtp } from "../../Utils/otp";
import { setErrors } from "./uiActions";

export const addToBasket = (order) => (dispatch) => {
  dispatch({
    type: ADD_TO_BASKET,
    payload: order,
  });
  dispatch({
    type: SET_SUCCESS,
    payload: `package ${order.order_id} added to basket`,
  });
};

export const handleRenderAllMultiple = (ordersObj, root_id) => (dispatch) => {
  dispatch({
    type: ADD_MULTIPLE_TO_BASKET,
    payload: { ordersObj, root_id },
  });

  dispatch({
    type: SET_SUCCESS,
    payload: `packages added to basket`,
  });
};

export const dispatchOrder =
  (order, rider_id, fcmToken, handleClose) => (dispatch) => {
    dispatch({ type: LOADING_UI });

    const orderData = {
      order,
      rider_id,
      status: "assigned",
      fcmToken,
    };

    axios
      .post("/dispatch", orderData)
      .then((res) => {
        handleClose(); // close assign modal
        dispatch({
          type: SET_SUCCESS,
          payload: ` ${order.order_id} assigned to rider successfully`,
        });

        // TODO: migrate to node queues
        return Promise.allSettled([
          dispatch(
            sendRiderSms({
              ...order,
              message: `Dear ${order.rider_name}, a delivery package by ${order.sender_name} of ${order.sender_phone} has been assigned to you. Please check your device for further information. Qwib`,
            })
          ),

          dispatch(
            sendReceiverSms({
              ...order,
              message: `Dear ${order.receiver_name}, a delivery package by ${order.sender_name} of ${order.sender_phone} has been sent to you. Your package is on transit by rider ${order.rider_name} of ${order.rider_phone}. Qwib`,
            })
          ),
        ]);
      })
      .then(() => {
        dispatch({
          type: SET_SUCCESS,
          payload: `messages sent to rider ${order.rider_name} and client ${order.receiver_name}`,
        });
        // clear state for unassigned selected
        dispatch({
          type: UN_ASSIGNED_SELECTED,
          payload: null,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch(setErrors(err));
      });
  };

export const cancelOrder = (order, order_id) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  axios
    .post(`/dispatch/cancelOrder/${order_id}`, order)
    .then(() => {
      dispatch({
        type: SET_SUCCESS,
        payload: `${order_id} cancelled successfully`,
      });
    })
    .catch((error) => dispatch(setErrors(error)));
};

export const cancelOrderMultiple = (root_id, order, order_id) => (dispatch) => {
  dispatch({ type: LOADING_UI });

  const payload = {
    order_id,
    order,
  };

  axios
    .post(`/dispatch/cancelOrderMultiple/${root_id}`, payload)
    .then((res) => {
      console.log(JSON.stringify(res.data, null, 2), "panahaa");
      dispatch({
        type: SET_SUCCESS,
        payload: `${order_id} cancelled successfully`,
      });

      dispatch(removeFromBasket(order_id));
    })
    .catch((error) => dispatch(setErrors(error)));
};

export const dispatchOrders =
  (orderMap, rider_id, data, fcmToken, handleClose, root_id = null) =>
  (dispatch, getState, { getFirebase }) => {
    dispatch({ type: LOADING_UI });

    const assigned_at = Date.now();

    const [updates, keys] = loopOrdersAddRiderId(
      orderMap,
      rider_id,
      data,
      assigned_at
    );

    const dataUpdated = {
      updates,
      keys,
      rider_id,
      rider: {
        ...data,
        rider_id,
      },
      fcmToken,
      root_id,
    };

    axios
      .post("/dispatch/many", dataUpdated)
      .then((res) => {
        handleClose();
        dispatch({
          type: SET_SUCCESS,
          payload: `orders assigned to rider successfully`,
        });
        dispatch({
          type: CLEAR_BASKET,
        });
        dispatch(
          sendRiderSms({
            ...data,
            message: `Dear ${data.rider_name}, a multiple delivery packages request has been assigned to you. Please check your device for further information`,
          })
        );
      })
      .catch((err) => {
        dispatch(setErrors(err));
      });
  };

export const clearBasket = () => (dispatch) => {
  dispatch({
    type: CLEAR_BASKET,
  });
  dispatch({
    type: SET_SUCCESS,
    payload: `basket cleared`,
  });
};

export const removeFromBasket = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_BASKET,
    payload: id,
  });
};

export const isDuplicate = (orderMap, order_id, order) => (dispatch) => {
  const isDup = orderMap[order_id] ? true : false;

  isDup
    ? dispatch({
        type: SET_ERRORS,
        payload: {
          message: "package already exists in basket",
        },
      })
    : dispatch(addToBasket(order));
};

// helpers
export const isDuplicate2 = (orderMap, order_id) => {
  const isDup = orderMap[order_id] ? true : false;

  return isDup;
};

const loopOrdersAddRiderId = (orderMap, rider_id, data, assigned_at) => {
  // loop orders
  // add order_status, rider_id

  /**
   * // create batch update object to  delete pending orders
   *  {
   * "/path/to/node/one": null,
   *  "/path/to/node/two": null,
   *  "/path/to/node/three": null
   * }
   */
  const updates = {};
  const keys = {};

  console.log(data);

  Object.entries(orderMap).forEach(([key, value]) => {
    updates[key] = {
      ...value,
      ...data,
      rider_id,
      order_status: "assigned",
      assigned_at,
      timestamp: assigned_at,
      proof_of_delivery: generateOtp(),
      delivery_type: "multiple",
    };
    keys[`/AdminDeliveries/pending_deliveries/${key}`] = null;
  });

  return [updates, keys];
};
