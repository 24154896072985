import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { DeletePopUpDialog as ConfirmDialog } from "../../../Base/DeleteDialog";
// mui
import DriverCreate from "./DriverCreate";
import Loader from "../../../Base/Loader";
import MaterialTable from "../../../Base/Table/MaterialTable";
import PeopleDialog from "../../../Base/People/PeopleDialog";
import PropTypes from "prop-types";
import React from "react";
// component
import SummaryCard from "../../../Base/SummaryCard";
import Toolbar from "@material-ui/core/Toolbar";
// redux
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { useFirebaseConnect, isLoaded, isEmpty } from "react-redux-firebase";

const columns = [
  {
    field: "id",
    headerName: "ID",
    title: "ID",
    width: 78,
    filtering: false,
  },
  {
    field: "fullName",
    headerName: "Full Name",
    title: "Full Name",
    width: 78,
    render: (rowData) => `${rowData.first_name} ${rowData.last_name}`,
    filterPlaceholder: "Filter by name",
  },
  {
    field: "email",
    headerName: "Email",
    title: "Email",
    width: 150,
    filterPlaceholder: "Filter by email",
  },
  {
    field: "id_number",
    headerName: "ID Number",
    width: 150,
    title: "ID Number",
    filterPlaceholder: "Filter by id number",
  },
  {
    field: "phone_number",
    headerName: "Phone Number",
    title: "Phone Number",
    width: 150,
    filterPlaceholder: "Filter by phone number",
  },
  {
    field: "is_active",
    headerName: "Account Status",
    width: 78,
    title: "Account Status",
    render: (rowData) => (rowData.is_active ? `Active` : `Suspended`),
    filterPlaceholder: "Account Status",
  },
  {
    field: "number_plate",
    headerName: "Vehicle Registration",
    width: 78,
    title: "Vehicle Registration",
    filterPlaceholder: "Filter by number plate",
  },
];

function createData(
  idx,
  name,
  profileImage,
  id_number,
  first_name,
  last_name,
  email,
  phone_number,
  number_plate,
  is_active,
  key
) {
  return {
    id: idx + 1,
    name,
    profileImage,
    id_number,
    first_name,
    last_name,
    email,
    phone_number,
    number_plate,
    is_active,
    key,
  };
}

function createRows(rows) {
  const arr = [];
  rows.forEach(
    (
      {
        value: {
          name,
          profileImage,
          id_number,
          first_name,
          last_name,
          email,
          phone_number,
          number_plate,
          is_active,
        },
        key,
      },
      index
    ) => {
      arr.push(
        createData(
          index,
          name,
          profileImage,
          id_number,
          first_name,
          last_name,
          email,
          phone_number,
          number_plate,
          is_active,
          key
        )
      );
    }
  );

  return arr;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  grow: {
    flexGrow: 0.6,
    flexShrink: 0.6,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    // padding: theme.spacing(1),
  },
}));

function DriverTable(props) {
  const classes = useStyles();
  const { openInPopup, confirmDialog, setConfirmDialog } = props;

  useFirebaseConnect("All Riders");

  React.useEffect(() => {
    console.log("i ran kwa table drivers");
  });

  const drivers = useSelector((state) => state.firebase.ordered["All Riders"]);

  if (!isLoaded(drivers)) {
    return <Loader />;
  }

  if (isEmpty(drivers)) {
    return <SummaryCard title="No riders found" />;
  }

  return (
    <>
      <div className={classes.root}>
        <Toolbar>
          <div edge="start" className={classes.grow} />
          <PeopleDialog
            title="Create Rider"
            edge="end"
            render={(open) => (
              <Button
                edge="end"
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={open}
              >
                Add Person
              </Button>
            )}
          >
            <DriverCreate />
          </PeopleDialog>
        </Toolbar>

        <div className={classes.summaryCard}>
          <MaterialTable
            title="Riders Information"
            columns={columns}
            actions={[
              {
                icon: "visibility",
                tooltip: "View User",
                onClick: (event, rowData) =>
                  openInPopup({
                    key: rowData.key,
                    value: {
                      name: rowData.name,
                      profileImage: rowData.profileImage,
                      id_number: rowData.id_number,
                      first_name: rowData.first_name,
                      last_name: rowData.last_name,
                      email: rowData.email,
                      phone_number: rowData.phone_number,
                      number_plate: rowData.number_plate,
                      is_active: rowData.is_active,
                      user_id: rowData.key,
                    },
                  }),
              },
            ]}
            data={createRows(drivers)}
            options={{
              exportFileName: "Rider Informations",
              actionsColumnIndex: -1,
            }}
          />
        </div>

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </div>
    </>
  );
}

DriverTable.propTypes = {
  ui: PropTypes.object.isRequired,
  openInPopup: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default React.memo(DriverTable);
