import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import ListItemInfo from "./ListItemInfo";
import C from "./index";
import { formatOrdersObjArray } from "../utils";
import { handleRenderAllMultiple } from "../../../../../../../../../Redux/Actions/basketActions";
import { useDispatch } from "react-redux";
/*eslint-disable eqeqeq*/
export default function ListCollapse({
  classes,
  selectedIndex,
  index,
  orders,
  multipleOrderKey,
  // handleListItemClick,
  isMultiple,
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedIndexInner, setSelectedIndexInner] = React.useState(0);
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(!open);
    dispatch(handleRenderAllMultiple({ ...orders }, multipleOrderKey));
  };

  const handleListItemClick = (event, index, order = {}) => {
    setSelectedIndexInner(index);
    // check type
    // if (listType == "assigned") {
    //   handleToggleDetailsView({ order, toggle: true });
    // } else if (listType == "unassigned") {
    //   handlePanToOrder(order);
    // }
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        selected={selectedIndex === index}
        key={multipleOrderKey}
      >
        <ListItemIcon>
          <CardGiftcardIcon />
        </ListItemIcon>
        <ListItemText primary="Nested Orders" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nested}>
          {formatOrdersObjArray(orders).map(
            (
              {
                order_status,
                receiver_address,
                receiver_lat,
                receiver_long,
                order_id,
                order_time,
                receiver_name,
                receiver_phone,
                sender_name,
                sender_phone,
                order_date,
                receiver_id,
                sender_id,
                sender_lat,
                sender_long,
                rider_id,
                rider_lat,
                rider_long,
                rider_email,
                rider_id_number,
                rider_plate,
                rider_image,
                rider_name,
                rider_phone,
                fee_paid_by,
                receiver_permanent_address,
                timestamp,
                delivery_schedule,
                delivery_fee = undefined,
                ...rest
              },
              index
            ) => (
              <>
                <ListItemInfo
                  isMultiple={isMultiple}
                  key={order_id}
                  selectedIndex={selectedIndexInner}
                  index={index}
                  handleListItemClick={handleListItemClick}
                  classes={classes}
                  value={{
                    order_status,
                    receiver_address,
                    receiver_lat,
                    receiver_long,
                    order_id,
                    order_time,
                    receiver_name,
                    receiver_phone,
                    sender_name,
                    sender_phone,
                    order_date,
                    receiver_id,
                    sender_id,
                    sender_lat,
                    sender_long,
                    rider_id,
                    rider_lat,
                    rider_long,
                    rider_email,
                    rider_id_number,
                    rider_plate,
                    rider_image,
                    rider_name,
                    rider_phone,
                    fee_paid_by,
                    receiver_permanent_address,
                    timestamp,
                    delivery_schedule,
                    delivery_fee,
                  }}
                />

                {(order_status == "pending" || order_status == "assigned") && (
                  <C.CancelOrderChip
                    order_status={order_status}
                    selectedIndex={selectedIndexInner}
                    index={index}
                    isMultiple={isMultiple}
                    multipleOrderKey={multipleOrderKey}
                    value={{
                      order_status,
                      receiver_address,
                      receiver_lat,
                      receiver_long,
                      order_id,
                      order_time,
                      receiver_name,
                      receiver_phone,
                      sender_name,
                      sender_phone,
                      order_date,
                      receiver_id,
                      sender_id,
                      sender_lat,
                      sender_long,
                      rider_id,
                      rider_lat,
                      rider_long,
                      rider_email,
                      rider_id_number,
                      rider_plate,
                      rider_image,
                      rider_name,
                      rider_phone,
                      fee_paid_by,
                      receiver_permanent_address,
                      timestamp,
                      delivery_schedule,

                      ...rest,
                    }}
                  />
                )}
              </>
            )
          )}
        </List>
      </Collapse>
    </>
  );
}
