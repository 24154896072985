import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PeopleDialog from "../../../Base/DeleteDialog";
import Button from "@material-ui/core/Button";

function MerchantActionButton(props) {
    const {
        record: {
            value: { is_active, name, user_id },
        },
        closeModal,
        handleSuspend,
    } = props;
    return (
        <>
            {is_active ? (
                <PeopleDialog
                    onSave={() => {
                        handleSuspend({
                            id: user_id,
                            closeModal,
                            disabled: true,
                        });
                    }}
                    title="Suspend Merchant"
                    content={`This action will prevent the user to access their account. Are you sure you wish suspend ${name} ?`}
                    render={(open) => (
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<LockIcon />}
                            onClick={open}
                        >
                            Suspend
                        </Button>
                    )}
                />
            ) : (
                <PeopleDialog
                    onSave={() => {
                        handleSuspend({
                            id: user_id,
                            closeModal,
                            disabled: false,
                        });
                    }}
                    title="Activate Merchant"
                    content={`This action will allow the user to access their account. Are you sure you wish reactivate ${name} ?`}
                    render={(open) => (
                        <Button
                            color="secondary"
                            variant="contained"
                            startIcon={<LockOpenIcon />}
                            onClick={open}
                        >
                            Activate
                        </Button>
                    )}
                />
            )}
        </>
    );
}

export default MerchantActionButton;
