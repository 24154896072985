import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import Content from "../../../Container/Content";
import DeleteIcon from "@material-ui/icons/Delete";
import { Dialog } from "@material-ui/core";
import DriveIcon from "@material-ui/icons/SportsMotorsports";
import DriverEditForm from "./DriverEditForm";
// import DriverStats from "./DriverStats";
import DriverSuspendActions from "./DriverSuspendActions";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import PeopleDialog from "../../../Base/DeleteDialog";
import PeopleEditDialog from "../../../Base/People/PeopleDialog";
import PropTypes from "prop-types";
import Rating from "@material-ui/lab/Rating";
import React from "react";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "relative",
    height: "100px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  header: {
    display: "flex",
    position: "absolute",
    width: "calc(100%)",
    top: "-70px",
    alignItems: "flex-end",
    "& > *": {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  spacer: {
    flexGrow: "1",
  },
  avatar: {
    border: `3px solid white`,
    width: theme.spacing(13),
    height: theme.spacing(13),
    boxShadow: theme.shadows[3],
  },
  actionGroup: {
    display: "flex",
    width: "330px",
    justifyContent: "space-between",
    marginRight: 0,
  },
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DriverProfile(props) {
  /* eslint-disable no-unused-vars */
  const { record, openPopup, handleClose, handleDelete, handleSuspend } = props;
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullScreen
        open={openPopup}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {record.value.user_id}{" "}
              {/* {record.value.last_name}{" "}
                                {record.current_proposal_status_id} */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Content>
          <div
            style={{
              height: "200px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              filter: "contrast(75%)",
              backgroundImage: "url(/img/wallpaper.jpeg)",
            }}
          />
          <div className={classes.headerContainer}>
            <div className={classes.header}>
              <Avatar
                alt={record.value.first_name}
                src={record.value.profileImage}
                classes={{
                  root: classes.avatar,
                  circle: classes.circle,
                }}
              />
              <Typography variant={"h5"}>{record.value.first_name}</Typography>
              <Chip variant={"outlined"} icon={<DriveIcon />} label="Rider" />
              <Rating name="read-only" value={4.3} readOnly />
              <div className={classes.spacer} />
              <div className={classes.actionGroup}>
                <PeopleEditDialog
                  title="Edit Rider"
                  render={(open) => (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                      onClick={open}
                    >
                      Edit
                    </Button>
                  )}
                >
                  <DriverEditForm record={record} closeModal={handleClose} />
                </PeopleEditDialog>

                <DriverSuspendActions
                  record={record}
                  closeModal={handleClose}
                  handleSuspend={handleSuspend}
                />
                <PeopleDialog
                  onSave={() => {
                    handleDelete({
                      id: record.value.user_id,
                      closeModal: handleClose,
                    });
                  }}
                  title="Delete Rider"
                  content={`This action is not reversible. Are you sure you wish delete ${record.value.first_name} to proceed ?`}
                  render={(open) => (
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={open}
                    >
                      Delete
                    </Button>
                  )}
                />
              </div>
            </div>
          </div>
          {/* <DriverStats rider_id={record.value.user_id} /> */}

          {/* <div className={classes.summaryCards}>
                            <SummaryCard
                                title="Last 30 Days"
                                component={<RevenueLine />}
                            />
                            <SummaryCard
                                title="By Vehicle"
                                component={<VehiclePie />}
                            />
                        </div>
                        <SummaryCard
                            title={"Recent expenses"}
                            component={<ExpensesTable />}
                        /> */}
        </Content>
      </Dialog>
    </>
  );
}

// const mapStateToProps = (state, ownProps) => {
//     const userId = ownProps.record.key;
//     const histories = state.firebase.data[`/All Bookings/${userId}`];

//     console.log(state.firebase.ordered);
//     console.log(histories);
//     return {
//         post: get(state.firebase.data, `/All Bookings/${userId}`),
//     };
// };

DriverProfile.propTypes = {
  loadMe: PropTypes.func.isRequired,
  ui: PropTypes.object.isRequired,
  openInPopup: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
// const enhance = compose(

//     firebaseConnect((props) => [
//         {
//             path: `/All Bookings/${props.record.key}`,
//         },
//     ])
// );

export default DriverProfile;
