import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import Typography from "@material-ui/core/Typography";

export default function ColorsTimeline(props) {
    /* eslint-disable no-unused-vars */
    const {
        data: {
            sender_name,
            sender_lat,
            sender_long,
            receiver_lat,
            receiver_long,
            receiver_name,
            receiver_address,
        },
    } = props;
    return (
        <Timeline>
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography color="textSecondary">From</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="secondary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography>{sender_name}</Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography color="textSecondary">To </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="primary" />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography>
                        {" "}
                        {receiver_name}, {receiver_address}
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}

export const TimelineDots = ColorsTimeline;
