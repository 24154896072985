import "./index.css";

import store, { persistor, rrfProps } from "./Redux/store";

import App from "./App/App";
import { PersistGate } from "redux-persist/integration/react";
//redux
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
// import { browserHistory } from 'react-router'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <Router>
                        <App />
                    </Router>
                </ReactReduxFirebaseProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
