import {
    SET_ERRORS,
    LOADING_UI,
    CLEAR_ERRORS,
    SET_SUCCESS,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    CREATE_DRIVER,
    FIREBASE_AUTH_ERROR,
    FIREBASE_AUTH_SUCCESS,
    PROFILE_DETAIL_ERROR,
    PROFILE_DETAIL_SUCCESS,
    VEHICLE_DETAIL_SUCCESS,
    VEHICLE_DETAIL_ERROR,
    CLEAR_SUCCESS,
} from "../types";
/* eslint-disable eqeqeq*/
export const createDriver = (driverData) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });
        const firebase = getFirebase();

        firebase
            .push("ALL DRIVERS", driverData)
            .then((res) => {
                dispatch({ type: CREATE_DRIVER, payload: res });
                dispatch({ type: CLEAR_ERRORS });
            })
            .catch((err) => dispatch({ type: SET_ERRORS, payload: err }));
    };
};

export const loadMe = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signInWithEmailAndPassword(
                process.env.REACT_APP_MEE,
                process.env.REACT_APP_ME
            )
            .then(() => {
                dispatch({ type: FIREBASE_AUTH_SUCCESS });
            })
            .catch((error) => {
                dispatch({ type: FIREBASE_AUTH_ERROR, payload: error });
            });
    };
};

export const signUpDriver = ({ email, password, name }) => {
    return (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });
        const firebase = getFirebase();
        let userId = "";
        // let token = "";

        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((data) => {
                userId = data.user.uid;
                return data.user.getIdToken();
            })
            .then((_token) => {
                // token = _token;
                return firebase.database().ref(`/All Drivers/${userId}/`).set({
                    email,
                    name,
                });
            })
            .then(() => {
                dispatch({
                    type: SET_SUCCESS,
                    payload: "account created successfully",
                });
                dispatch({ type: SIGNUP_SUCCESS });
                dispatch({ type: CLEAR_ERRORS });
            })
            .catch((error) => {
                dispatch({ type: SIGNUP_ERROR, payload: error });
                dispatch({ type: SET_ERRORS, payload: error });
            });
    };
};

export const updateDriver = ({ uid, driverData, action }) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .database()
            .ref(`/All Drivers/${uid}/`)
            .update({
                ...driverData,
            })
            .then(() => {
                dispatch({ type: SET_SUCCESS, payload: "success" });
                if (action == "personalDetails") {
                    dispatch({ type: PROFILE_DETAIL_SUCCESS });
                } else if (action == "vehicleDetails") {
                    dispatch({ type: VEHICLE_DETAIL_SUCCESS });
                }
            })
            .catch((error) => {
                dispatch({ type: SET_ERRORS, payload: error });

                if (action == "personalDetails") {
                    dispatch({ type: PROFILE_DETAIL_ERROR });
                } else if (action == "vehicleDetails") {
                    dispatch({ type: VEHICLE_DETAIL_ERROR });
                }
            });
    };
};

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch({ type: SIGNOUT_SUCCESS });
            });
    };
};

export const clearSuccess = () => (dispatch) => {
    dispatch({ type: CLEAR_SUCCESS });
};
