import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import { IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Typography from "@material-ui/core/Typography";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import C from "./customMultipleList/index";
import { formatCash } from "../../../../../../../../Utils/formatDate";

function CustomAssignedUnassignedListComponent({
  orders,
  selectedIndex,
  handleListItemClick,
  classes,
}) {
  /* eslint-disable  eqeqeq */
  return (
    <List component="nav" aria-label="orders">
      {[...orders].map(
        (
          {
            key,
            value: {
              order_status,
              receiver_address,
              receiver_lat,
              receiver_long,
              order_id,
              order_time,
              receiver_name,
              receiver_phone,
              sender_name,
              sender_phone,
              order_date,
              receiver_id,
              sender_id,
              sender_lat,
              sender_long,
              rider_id = undefined,
              rider_lat = undefined,
              rider_long = undefined,
              rider_email = undefined,
              rider_id_number = undefined,
              rider_plate = undefined,
              rider_image = undefined,
              rider_name = undefined,
              rider_phone = undefined,
              fee_paid_by = undefined,
              receiver_permanent_address = undefined,
              timestamp = undefined,
              delivery_schedule,
              discounted_amount = 0,
              ...rest
            },
          },
          index
        ) => (
          <>
            <ListItem
              key={key}
              button
              selected={selectedIndex === index}
              onClick={(event) => {
                const order = {
                  order_status,
                  receiver_address,
                  receiver_lat,
                  receiver_long,
                  order_id,
                  order_time,
                  receiver_name,
                  receiver_phone,
                  sender_name,
                  sender_phone,
                  order_date,
                  receiver_id,
                  sender_id,
                  rider_id,
                  sender_lat,
                  sender_long,
                  rider_lat,
                  rider_long,
                  rider_email,
                  rider_id_number,
                  rider_plate,
                  rider_image,
                  rider_name,
                  rider_phone,
                  fee_paid_by,
                  delivery_schedule,
                  receiver_permanent_address,
                  discounted_amount,
                  ...rest,
                };
                handleListItemClick(event, index, order);
              }}
            >
              <ListItemText
                primary={receiver_name}
                secondary={
                  <React.Fragment>
                    {discounted_amount && (
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Discount Amount{" "}
                        {formatCash(parseFloat(discounted_amount))}
                      </Typography>
                    )}

                    <Typography gutterBottom variant="body2" component="body2">
                      Schedule: {delivery_schedule}
                    </Typography>

                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Client Phone: {receiver_phone}
                    </Typography>
                    <Typography
                      className={classes.title}
                      gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      Merchant: {sender_name}
                    </Typography>
                    <Chip
                      size="small"
                      label={`${order_status}`}
                      clickable
                      color={`${
                        order_status == "assigned" ||
                        order_status == "assigned rider"
                          ? "primary"
                          : "secondary"
                      }`}
                    />
                  </React.Fragment>
                }
              />
              {delivery_schedule == "INSTANT" && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" color="primary">
                    {delivery_schedule == "INSTANT" ? (
                      <FlashOnIcon />
                    ) : (
                      <AccessAlarmIcon />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>

            {(order_status == "pending" || order_status == "assigned") && (
              <C.CancelOrderChip
                order_status={order_status}
                selectedIndex={selectedIndex}
                index={index}
                value={{
                  order_status,
                  receiver_address,
                  receiver_lat,
                  receiver_long,
                  order_id,
                  order_time,
                  receiver_name,
                  receiver_phone,
                  sender_name,
                  sender_phone,
                  order_date,
                  receiver_id,
                  sender_id,
                  rider_id,
                  sender_lat,
                  sender_long,
                  rider_lat,
                  rider_long,
                  rider_email,
                  rider_id_number,
                  rider_plate,
                  rider_image,
                  rider_name,
                  rider_phone,
                  fee_paid_by,
                  delivery_schedule,
                  receiver_permanent_address,
                  ...rest,
                }}
              />
            )}
            <Divider />
          </>
        )
      )}
    </List>
  );
}

export default CustomAssignedUnassignedListComponent;
