import { isEmpty, isLoaded } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";

import { DataGrid } from "@material-ui/data-grid";
import Loader from "../../../Base/Loader";
import React from "react";
import Title from "../../../Base/Title";
import { commissionFeeByRiders } from "../../../../Redux/Actions/reportActions";
import { formatCash } from "../../../../Utils/formatDate";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const columns = [
  { field: "id", headerName: "ID", width: 78 },
  { field: "rider_name", headerName: "Rider name", width: 200 },
  { field: "trips", headerName: "Trips", width: 150 },
  { field: "total", headerName: "Transactions", width: 200 },
];

function createData(idx, total_orders, rider_name, total) {
  return {
    id: idx + 1,
    rider_name,
    trips: total_orders,
    total,
  };
}

function createRows(rows) {
  const arr = [];
  rows.forEach(({ rider_name, total_orders, total }, index) => {
    arr.push(createData(index, total_orders, rider_name, formatCash(total)));
  });

  return arr;
}

function CommissionsTablePerRider() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rowsObj = useSelector((state) => state.report.ridersCommissions);

  React.useEffect(() => {
    if (isLoaded(rowsObj) && isEmpty(rowsObj.results)) {
      dispatch(commissionFeeByRiders({ limit: 50, nextPage: "" }));
    }
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  if (!isLoaded(rowsObj) || rowsObj.loading) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <>
        {rowsObj.results == null ? (
          <Title>No Data to display</Title>
        ) : (
          <>
            <Title>Riders Stats</Title>
            <DataGrid
              rows={createRows(rowsObj.results)}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 15, 25, 50]}
              checkboxSelection
              disableSelectionOnClick
            />
          </>
        )}
      </>
    </div>
  );
}

export default CommissionsTablePerRider;
