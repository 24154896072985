import MuiTable from "material-table";
import React from "react";
function MaterialTable({ title, columns, data, actions, options }) {
  return (
    <MuiTable
      title={title}
      columns={columns}
      data={data}
      actions={actions}
      options={{
        pageSizeOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        exportAllData: true,
        exportButton: true,
        filtering: true,
        searchAutoFocus: true,
        sorting: true,
        ...options,
      }}
    />
  );
}

export default MaterialTable;
