import * as Yup from "yup";

// form stuff
import { Field, Form, Formik } from "formik";

import Button from "@material-ui/core/Button";
import Controls from "../../../Controls";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import { TextField } from "formik-material-ui";
// redux
import { useDispatch, useSelector } from "react-redux";
import { createNewRider } from "../../../../Redux/Actions/riderActions";
import useImageHandler from "../../../../Hooks/useImageHandler";

const validationSchema = Yup.object({
  first_name: Yup.string().required("first Name is required"),
  last_name: Yup.string().required("last Name is required"),
  id_number: Yup.number().required("national identification is required"),
  email: Yup.string().email().required("email is required"),
  number_plate: Yup.string()
    .max(10, "must not exceed 10 characters")
    .required("number plate eg kcb 145e is required"),
  phone_number: Yup.string()
    .min(10, "must be atleast 10 characters long")
    .max(15, "must not exceed 15 characters")
    .required("phone number is required"),
  password: Yup.string()
    .min(4)
    .max(8, "max password length is 8 characters")
    .required(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function DriverCreate(props) {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);

  /* eslint-disable no-unused-vars */

  const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
    useImageHandler();

  const createRider = (values, action) => {
    const rider = {
      ...values,
      profileImage:
        imageUrl == null
          ? "https://i.pinimg.com/originals/c1/94/c7/c194c7141911512614e8418c8be92f33.jpg"
          : imageUrl,
    };
    dispatch(createNewRider({ rider, closeModal: handleClose }));
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        id_number: "",
        email: "",
        number_plate: "",
        phone_number: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={createRider}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Field
                name="first_name"
                type="text"
                label="First Name"
                component={TextField}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="last_name"
                type="text"
                label="Last Name"
                component={TextField}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="id_number"
                type="number"
                label="ID Number"
                component={TextField}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="phone_number"
                type="text"
                label="Phone Number"
                component={TextField}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="email"
                type="text"
                label="Email"
                component={TextField}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="number_plate"
                type="text"
                label="Number Plate"
                component={TextField}
                fullWidth
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.PasswordInput
                name="password"
                label="Password"
                fullWidth
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.PasswordInput
                name="confirmPassword"
                label="Confirm Password"
                fullWidth
                variant="outlined"
                margin="dense"
              />
            </Grid>

            <Grid item container xs={6}>
              {/* <Grid item xs={12}>
                            {imageUrl ? (
                                <img
                                    style={{
                                        width: "256px",
                                        height: "256px",
                                    }}
                                    src={imageUrl}
                                />
                            ) : (
                                <img
                                    style={{
                                        width: "256px",
                                        height: "256px",
                                    }}
                                    src="https://www.ukje.shop/media/catalog/product/cache/aefcd4d8d5c59ba860378cf3cd2e94da/m/i/mintgroene-voetenzak-5-punts-gordel.jpg"
                                />
                            )}
                        </Grid> */}
              {/* <Grid item xs={12}>
                            {file == null && (
                                <UploadButton
                                    imageChangeHandler={imageChangeHandler}
                                    title={
                                        file == null
                                            ? "Upload User Image"
                                            : "User Image Uploaded Successfully"
                                    }
                                    fullWidth
                                />
                            )}

                            {file && (
                                <ProgressBar
                                    file={file}
                                    setFile={setFile}
                                    setImageUrl={setImageUrl}
                                />
                            )}
                        </Grid>*/}
            </Grid>

            <Grid item xs={8}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="medium"
                style={{
                  marginRight: "10px",
                }}
              >
                Create
                {loading && (
                  <CircularProgress
                    size={30}
                    style={{
                      position: "absolute",
                    }}
                  />
                )}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={handleClose}
                style={{
                  marginLeft: "10px",
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

DriverCreate.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default DriverCreate;
