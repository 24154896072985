import AddToBasket from "./AddToBasket";
import AssignDialog from "./AssignRiderModal";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import TimelineDots from "../../map/orderListContainer/orderDetails/TimelineDots";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
});

export default function MediaCard({
  order: {
    value: {
      order_status,
      receiver_address,
      receiver_lat,
      receiver_long,
      order_id,
      order_time,
      receiver_name,
      receiver_phone,
      sender_name,
      sender_phone,
      order_date,
      receiver_id,
      sender_id,
      sender_lat,
      sender_long,
      rider_id = undefined,
      receiver_permanent_address,
      fee_paid_by,
      ...rest
    },
  },
}) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Order Status: {order_status} @{order_time}
          </Typography>
          {/* <Typography gutterBottom variant="h5" component="h2">
                        {receiver_address}
                    </Typography> */}
          <TimelineDots
            data={{
              sender_name,
              sender_lat,
              sender_long,
              receiver_lat,
              receiver_long,
              receiver_name,
              receiver_address,
            }}
          />
          <Typography variant="body2" color="textSecondary" component="p">
            {receiver_name} {receiver_phone}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <AssignDialog
          order={{
            order_status,
            receiver_address,
            receiver_lat,
            receiver_long,
            order_id,
            order_time,
            receiver_name,
            receiver_phone,
            sender_name,
            sender_phone,
            order_date,
            receiver_id,
            sender_id,
            sender_lat,
            sender_long,
            rider_id,
            receiver_permanent_address,
            fee_paid_by,
            ...rest,
          }}
        />
        <Typography variant="h5" component="h2">
          {bull}or{bull}
        </Typography>
        <AddToBasket
          order={{
            order_status,
            receiver_address,
            receiver_lat,
            receiver_long,
            order_id,
            order_time,
            receiver_name,
            receiver_phone,
            sender_name,
            sender_phone,
            order_date,
            receiver_id,
            sender_id,
            sender_lat,
            sender_long,
            rider_id,
            receiver_permanent_address,
            fee_paid_by,
            ...rest,
          }}
        />
      </CardActions>
    </Card>
  );
}

export function OrderInfoMultiple({
  order: {
    order_status,
    receiver_address,
    receiver_lat,
    receiver_long,
    order_id,
    order_time,
    receiver_name,
    receiver_phone,
    sender_name,
    sender_phone,
    order_date,
    receiver_id,
    sender_id,
    sender_lat,
    sender_long,
    rider_id = undefined,
    receiver_permanent_address,
    fee_paid_by,
  },
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Order Status: {order_status} @{order_time}
          </Typography>
          {/* <Typography gutterBottom variant="h5" component="h2">
                        {receiver_address}
                    </Typography> */}
          <TimelineDots
            data={{
              sender_name,
              sender_lat,
              sender_long,
              receiver_lat,
              receiver_long,
              receiver_name,
              receiver_address,
            }}
          />
          <Typography variant="body2" color="textSecondary" component="p">
            {receiver_name} {receiver_phone}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
