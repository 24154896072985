import {
    UPDATE_JOB,
    SET_CURRENT_JOB,
    CLEAR_CURRENT_JOB,
    SET_JOB,
    LOAD_JOBS,
    DELETE_JOB,
    FETCH_SINGLE_JOB,
} from "../types";

const initialState = {
    jobs: [],
    pageInfo: {},
    filteredJobs: null,
    currentJob: null,
};
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq*/
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_JOB:
            return {
                ...state,
                jobs: [action.payload, ...state.jobs],
            };

        case LOAD_JOBS:
            return {
                ...state,
                jobs: action.payload.results,
                pageInfo: action.payload.pageInfo,
            };

        case DELETE_JOB:
            return {
                ...state,
                jobs: [
                    ...state.jobs.filter((job) => job.id !== action.payload),
                ],
            };

        case UPDATE_JOB:
            return {
                ...state,
                jobs: state.jobs.map((job) =>
                    // check if incoming aritcle matches the jobs we have
                    // replace with the new =job
                    job.id == action.payload.id ? action.payload : job
                ),
                currentJob: {
                    ...state.currentJob,
                    ...action.payload,
                },
            };

        case SET_CURRENT_JOB:
        case FETCH_SINGLE_JOB:
            return {
                ...state,
                currentJob: action.payload,
            };
        case CLEAR_CURRENT_JOB:
            return {
                ...state,
                currentJob: null,
            };

        default:
            return state;
    }
}
