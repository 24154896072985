import { FILTER_INDUSTRY, SET_INDUSTRIES, CLEAR_INDUSTRY } from "../types";

const initialState = {
    industry: null,
    industries: null,
    filteredIndustries: null,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_INDUSTRIES:
            return {
                ...state,
                industries: action.payload,
            };

        case FILTER_INDUSTRY:
            return {
                ...state,
                filteredIndustries: state.industries.filter((industry) => {
                    const regex = new RegExp(`${action.payload}`, "gi");
                    return industry.industry_name.match(regex);
                }),
            };
        case CLEAR_INDUSTRY:
            return {
                ...state,
                filteredIndustries: null,
            };

        default:
            return state;
    }
};

export default auth;
