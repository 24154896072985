import React from "react";
import OrderListTab from "./tabsAndList";
import OrderDetails from "./orderDetails";
import { useSelector } from "react-redux";

function OrderListContainerTab() {
    /* eslint-disable eqeqeq */
    const toggleDetails = useSelector((state) => state.map.toggleDetails);
    return <> {toggleDetails == true ? <OrderDetails /> : <OrderListTab />}</>;
}

export default OrderListContainerTab;
