import { LOADING_UI, SET_SUCCESS } from "../types";

import axios from "axios";
import { db } from "../../Utils/fb.config";
import { setErrors } from "./uiActions";

// create store
export const createNewRider =
    ({ rider, closeModal }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        axios
            .post(`/riders/register`, rider)
            .then((res) => {
                closeModal();
                dispatch({
                    type: SET_SUCCESS,
                    payload: `rider created successfully`,
                });
            })
            .catch((err) => {
                console.log(`rider Create Error`);
                dispatch(setErrors(err));
            });
    };

export const deleteRider =
    ({ id, closeModal }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        axios
            .delete(`/riders/${id}`)
            .then((res) => {
                closeModal();
                dispatch({
                    type: SET_SUCCESS,
                    payload: `rider deleted successfully`,
                });
            })
            .catch((err) => {
                console.log(`rider Delete Error`);
                dispatch(setErrors(err));
            });
    };

export const editRider =
    ({ rider, closeModal, handleClose, user_id }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        db.ref(`/All Riders/${user_id}/`)
            .update({
                ...rider,
            })
            .then((value) => {
                handleClose();
                closeModal();
                dispatch({
                    type: SET_SUCCESS,
                    payload: `rider updated successfully`,
                });
            })
            .catch((err) => {
                console.log(`Rider update Error`);
                dispatch(setErrors(err));
            });
    };

export const suspendUser =
    ({ id, closeModal, disabled }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        axios
            .patch(`/riders/${id}`, { disabled })
            .then((res) => {
                closeModal(); // closes fullscreen modal
                dispatch({
                    type: SET_SUCCESS,
                    payload: `user suspended`,
                });
            })
            .catch((err) => {
                console.log(`user Suspend Error`);
                dispatch(setErrors(err));
            });
    };
