import { CancelChip } from "../OrderMenuOptions";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export default function CancelOrderChip({
  order_status,
  selectedIndex,
  index,
  value,
  isMultiple = null,
  multipleOrderKey = null,
}) {
  const {
    receiver_address,
    receiver_lat,
    receiver_long,
    order_id,
    order_time,
    receiver_name,
    receiver_phone,
    sender_name,
    sender_phone,
    order_date,
    receiver_id,
    sender_id,
    sender_lat,
    sender_long,
    rider_id,
    rider_lat,
    rider_long,
    rider_email,
    rider_id_number,
    rider_plate,
    rider_image,
    rider_name,
    rider_phone,
    fee_paid_by,
    receiver_permanent_address,
    timestamp,
    delivery_schedule,
    orders,
    ...rest
  } = value;

  return (
    <>
      <ListItem
        button
        variant="contained"
        color="textSecondary"
        selected={selectedIndex === index}
      >
        {" "}
        <ListItemText>
          <CancelChip
            order={{
              order_status,
              receiver_address,
              receiver_lat,
              receiver_long,
              order_id,
              order_time,
              receiver_name,
              receiver_phone,
              sender_name,
              sender_phone,
              order_date,
              receiver_id,
              sender_id,
              rider_id,
              sender_lat,
              sender_long,
              rider_lat,
              rider_long,
              rider_email,
              rider_id_number,
              rider_plate,
              rider_image,
              rider_name,
              rider_phone,
              fee_paid_by,
              delivery_schedule,
              receiver_permanent_address,
              ...rest,
            }}
            order_id={order_id}
            root_id={multipleOrderKey}
            isMultiple={isMultiple}
          />
        </ListItemText>
      </ListItem>
    </>
  );
}
