import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    media: {
        height: 140,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
}));

export default function MediaCard({
    rider: {
        value: {
            first_name,
            id_number,
            is_active,
            last_name,
            number_plate,
            phone_number,
            profileImage,
            rider_lat,
            rider_long,
            user_id,
            user_type,
        },
    },
}) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar
                        aria-label="recipe"
                        className={classes.avatar}
                        src={profileImage}
                    />
                }
                title={first_name}
                subheader={last_name}
            />
            <CardActionArea>
                <CardContent>
                    <Divider />

                    <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom
                    >
                        phone: {phone_number}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textPrimary"
                        component="p"
                    >
                        ID: {id_number}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" variant="contained" color="primary">
                    plate: {number_plate}
                </Button>
                <Button size="small" variant="contained" color="secondary">
                    account status: {is_active ? "Active" : "Suspended"}
                </Button>
            </CardActions>
        </Card>
    );
}
