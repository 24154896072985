import { InfoWindow, Marker } from "@react-google-maps/api";
import { isEmpty, isLoaded } from "react-redux-firebase";

import Loader from "../../../../../Base/Loader";
import { OrderInfoMultiple } from "./OrderInfoCard";
import React from "react";
import packageIcon from "../../../../../../box.svg";
import { useSelector } from "react-redux";

// <img src="https://img.icons8.com/emoji/48/000000/package-.png"/>
function MultipleOrderMarkers(props) {
  const { panTo } = props;
  const [selected, setSelected] = React.useState(null);

  const orders = useSelector((state) => {
    return state.basket.orders;
  });

  // panTo clicked unAssigned order
  /* eslint-disable  react-hooks/exhaustive-deps */
  React.useEffect(() => {
    if (!isEmpty(orders)) {
      const { receiver_lat, receiver_long } = orders[0];
      panTo({
        lat: receiver_lat,
        lng: receiver_long,
        zoom: 11,
      });
    } else {
      return "no order to panTo";
    }
  }, [orders]);

  if (!isLoaded(orders)) {
    return <Loader />;
  }

  if (isEmpty(orders)) {
    return null;
  }

  return (
    <div>
      {orders.length && (
        <>
          {orders.map((order) => (
            <Marker
              key={order.key}
              position={{
                lat: order.receiver_lat,
                lng: order.receiver_long,
              }}
              icon={{
                url: packageIcon,
                scaledSize: new window.google.maps.Size(30, 30),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 15),
              }}
              onClick={() => {
                setSelected(order);
                console.log("clicked");
              }}
            />
          ))}

          {/* render info */}
          {selected ? (
            <InfoWindow
              position={{
                lat: selected.receiver_lat,
                lng: selected.receiver_long,
              }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <OrderInfoMultiple order={selected} />
            </InfoWindow>
          ) : null}
        </>
      )}
    </div>
  );
}

export default MultipleOrderMarkers;
