import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
//mui stuff
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadButton from "../../../../../Base/UploadButton";
import useImageHandler from "../../../../../../Hooks/useImageHandler";
import ProgressBar from "../../../../../Base/ProgressBarImages";
import Controls from "../../../../../Controls";
import { updateDriver } from "../../../../../../Redux/Actions/driverActions";

const validationSchemaCompany = Yup.object({
    vehicleType: Yup.string().required("Model type is required").max(15),
    vehicleReg: Yup.string().required("Number plate is required").max(10),
    category: Yup.string().required("Select a category").max(15),
    subcategory: Yup.string().max(15),
});

const options = [
    {
        key: "1",
        value: {
            name: "Transportation",
            subcategories: [
                {
                    name: "Lorry",
                },
                {
                    name: "Pick Up",
                },

                {
                    name: "Tuk Tuk",
                },

                {
                    name: "Mkokoteni",
                },
            ],
        },
    },
    {
        key: "2",
        value: {
            name: "Water Bowser",
            subcategories: [
                {
                    name: "1000-5000 Lts",
                },
                {
                    name: "5000-10,000 Lts",
                },

                {
                    name: "10,000-20,000 Lts",
                },
                {
                    name: "20,000+ Lts",
                },
            ],
        },
    },
    {
        key: "3",
        value: {
            name: "Tow Trucks",
            subcategories: [
                {
                    name: "Flatbed Tow Truck",
                },
                {
                    name: "Hook and Chain Tow Truck",
                },

                {
                    name: "Wheel Lift Tow Truck",
                },
                {
                    name: "Integrated Tow Truck",
                },
            ],
        },
    },
    {
        key: "4",
        value: {
            name: "Sewerage Bowser",
            subcategories: [
                {
                    name: "1000-5000 Lts",
                },
                {
                    name: "5000-10,000 Lts",
                },

                {
                    name: "10,000-20,000 Lts",
                },
                {
                    name: "20,000+ Lts",
                },
            ],
        },
    },
];

function Page1AuthDetails(props) {
    const { classes, loading, updateDriver, auth, vehicleError, nextStep } =
        props;

    const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
        useImageHandler();

    const mapToOptions = (optionss) => {
        return optionss.map((item, index) => {
            return {
                id: item.key,
                value: item.value.name,
                subcategories: item.value.subcategories,
            };
        });
    };

    /* eslint-disable eqeqeq*/
    const getSubCategories = (index, optionsArr) => {
        const category = optionsArr.find((item) => item.id == index);
        return category.subcategories || [];
    };

    const mapCategoriesToOptions = (optionss) => {
        return optionss.map((item, index) => {
            return {
                id: index + 1,
                value: item.name,
            };
        });
    };

    const handleSubmit = (values, actions) => {
        const {
            vehicleType,
            vehicleReg,
            category,
            subcategory,
            subcategories,
        } = values;
        console.log("clicked", values);

        const driverData = {
            vehicleType,
            vehicleReg,
            category: options[parseInt(category)].value.name,
            subcategory: subcategories[parseInt(subcategory) - 1].value || "",
            vehicleImageUrl: imageUrl ?? "",
        };

        updateDriver({ uid: auth.uid, driverData, action: "vehicleDetails" });
    };

    return (
        <>
            {imageUrl ? (
                <Avatar className={classes.imageUrl} src={imageUrl} />
            ) : (
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
            )}
            <Typography component="h1" variant="h5">
                Vehicle Details (3)
            </Typography>

            <Formik
                initialValues={{
                    vehicleType: "",
                    vehicleReg: "",
                    category: "",
                    subcategory: "",
                }}
                validationSchema={validationSchemaCompany}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
                {(props) => {
                    const {
                        values,
                        // dirty,
                        // isSubmitting,
                        // handleChange,
                        // handleSubmit,
                        // handleReset,
                        setFieldValue,
                    } = props;
                    return (
                        <Form>
                            <Field
                                name="vehicleType"
                                type="text"
                                label="Model"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            />

                            <Field
                                name="vehicleReg"
                                type="text"
                                label="Number Plate"
                                variant="outlined"
                                component={TextField}
                                fullWidth
                            />

                            <Field
                                name="category"
                                as={Controls.Select}
                                options={mapToOptions(options)}
                                type="select"
                                label="Vehicle Category"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    console.log(e.target);

                                    const _subcategories = getSubCategories(
                                        value,
                                        mapToOptions(options)
                                    );

                                    setFieldValue("category", value);
                                    setFieldValue("subcategory", "");
                                    setFieldValue(
                                        "subcategories",
                                        mapCategoriesToOptions(_subcategories)
                                    );
                                }}
                            />
                            {values.subcategories && (
                                <Field
                                    value={values.subcategory}
                                    id="subcategory"
                                    name="subcategory"
                                    fullWidth
                                    label="Vehicle Subcategory"
                                    variant="outlined"
                                    component={Controls.Select}
                                    options={values.subcategories}
                                    type="select"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        console.log(value, e);

                                        setFieldValue("subcategory", value);
                                    }}
                                />
                            )}

                            {file == null && (
                                <UploadButton
                                    imageChangeHandler={imageChangeHandler}
                                    title={
                                        file == null
                                            ? "Upload Vehicle Picture"
                                            : "Vehicle Image Uploaded Successfully"
                                    }
                                />
                            )}

                            {file && (
                                <ProgressBar
                                    file={file}
                                    setFile={setFile}
                                    setImageUrl={setImageUrl}
                                />
                            )}

                            <div>
                                {vehicleError ? (
                                    <Button
                                        disabled={!vehicleError}
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        className={classes.submit}
                                        onClick={() => nextStep()}
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.submit}
                                        type="submit"
                                    >
                                        Submit Details
                                        {loading && (
                                            <CircularProgress
                                                size={30}
                                                className={classes.progress}
                                            />
                                        )}
                                    </Button>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

Page1AuthDetails.propTypes = {
    updateDriver: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    // console.log(state.firebase);

    return {
        auth: state.firebase.auth,
        vehicleError: state.driver.vehicleError,
    };
};

const mapActionsToProps = {
    updateDriver,
};

export default connect(mapStateToProps, mapActionsToProps)(Page1AuthDetails);
