import React from "react";
import PropTypes from "prop-types";

import * as Yup from "yup";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

//mui stuff
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

// components
import Copyright from "../../../../Base/Copyright";
import UploadButton from "../../../../Base/UploadButton";
import useImageHandler from "../../../../../Hooks/useImageHandler";
import ProgressBar from "../../../../Base/ProgressBarImages";

// redux
import { connect } from "react-redux";
import { registerCompany } from "../../../../../Redux/Actions/authActions";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            // width: 200,
        },
    },
    image: {
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        paddingTop: "40px",
    },
    paper: {
        margin: theme.spacing(8, 8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    progress: {
        position: "absolute",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const validationSchemaCompany = Yup.object({
    companyName: Yup.string().required("companyName is required"),
    companyDescription: Yup.string()
        .max(200, "must not exceed 200 characters")
        .required("company description is required"),
    companyWebsite: Yup.string().max(200, "must not exceed 15 characters"),
    companyEmail: Yup.string().email("invalid companyEmail address").required(),
});

const CompanyReg = (props) => {
    const classes = useStyles();

    const {
        auth: { user },
        ui: { loading },
    } = props;

    const { file, setFile, imageUrl, setImageUrl, imageChangeHandler } =
        useImageHandler();

    const handleSubmit = (values, actions, user) => {
        const {
            companyName,
            companyEmail,
            companyDescription,
            companyWebsite,
        } = values;

        const company = {
            name: companyName,
            description: companyDescription,
            logo_url:
                imageUrl == null
                    ? "https://i.pinimg.com/originals/c1/94/c7/c194c7141911512614e8418c8be92f33.jpg"
                    : imageUrl,
            website_url: companyWebsite,
            email: companyEmail,
            owner_id: user.id,
        };

        props.registerCompany(company, props.history);
        actions.resetForm();
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid container justify="center" className={classes.image}>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    direction="row"
                    elevation={6}
                    square
                >
                    <Grid className={classes.paper}>
                        {imageUrl ? (
                            <Avatar
                                className={classes.imageUrl}
                                src={imageUrl}
                            />
                        ) : (
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                        )}
                        <Typography component="h1" variant="h5">
                            Create Store Account
                        </Typography>

                        <Formik
                            initialValues={{
                                companyName: "",
                                companyEmail: "",
                                companyDescription: "",
                                companyWebsite: "",
                            }}
                            validationSchema={validationSchemaCompany}
                            onSubmit={(values, actions) =>
                                handleSubmit(values, actions, user)
                            }
                        >
                            <Form>
                                <Field
                                    name="companyName"
                                    type="text"
                                    label="Company Name"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                />

                                <Field
                                    name="companyDescription"
                                    type="text"
                                    label="Company Description"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                />

                                <Field
                                    name="companyWebsite"
                                    type="text"
                                    label="Company Website"
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                />

                                <Field
                                    variant="outlined"
                                    component={TextField}
                                    fullWidth
                                    label="CompanyEmail Address"
                                    name="companyEmail"
                                    autoComplete="companyEmail"
                                    autoFocus
                                />
                                {file == null && (
                                    <UploadButton
                                        imageChangeHandler={imageChangeHandler}
                                        title={
                                            file == null
                                                ? "Upload Logo"
                                                : "Logo Image Uploaded Successfully"
                                        }
                                    />
                                )}

                                {file && (
                                    <ProgressBar
                                        file={file}
                                        setFile={setFile}
                                        setImageUrl={setImageUrl}
                                    />
                                )}
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.submit}
                                    type="submit"
                                >
                                    Create Company
                                    {loading && (
                                        <CircularProgress
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button>
                            </Form>
                        </Formik>

                        <Grid container mt={5}>
                            <Grid item xs>
                                <Link to="/forgot-password" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/login" variant="body2">
                                    {"Already have an account? Log in"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

CompanyReg.propTypes = {
    registerCompany: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui,
});

const mapActionsToProps = {
    registerCompany,
};

export default connect(mapStateToProps, mapActionsToProps)(CompanyReg);
