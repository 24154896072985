import React, { useEffect } from "react";
import SummaryCard from "../../../../../Base/SummaryCard";
import { connect } from "react-redux";
import { clearSuccess } from "../../../../../../Redux/Actions/driverActions";

function Page4Success(props) {
    const { clearSuccess } = props;

    /* eslint-disable eqeqeq*/
    useEffect(() => {
        clearSuccess();
        /* eslint-disable react-hooks/exhaustive-deps*/
    }, []);

    return (
        <SummaryCard title="Congratulations, registration completed!! You can now proceed and login via the urady driver app." />
    );
}

const mapActionsToProps = {
    clearSuccess,
};

export default connect(null, mapActionsToProps)(Page4Success);
