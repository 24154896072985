import React from "react";

// redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
// form stuff
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { editMerchant } from "../../../../Redux/Actions/merchantActions";

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    kra_pin: Yup.string().required("kra pin is required"),
    phone: Yup.string()
        .min(10, "must be atleast 10 characters long")
        .max(15, "must not exceed 15 characters")
        .required("phone number is required"),
});

function MerchantEditForm(props) {
    /* eslint-disable no-unused-vars */
    const {
        closeModal,
        handleClose, //passed by peopleModal via react.clone
        editMerchant,
        record: {
            value: {
                name,
                email,
                is_active,
                kra_pin,
                ongoing,
                profileImage,
                phone,
                user_id,
            },
        },
    } = props;

    const editCurrentMerchant = (values, action) => {
        const merchant = {
            ...values,
        };
        editMerchant({ merchant, closeModal, handleClose, user_id });
    };

    return (
        <Formik
            initialValues={{
                name,
                kra_pin,
                phone,
            }}
            validationSchema={validationSchema}
            onSubmit={editCurrentMerchant}
        >
            <Form>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Field
                            name="name"
                            type="text"
                            label="Name"
                            component={TextField}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="kra_pin"
                            type="text"
                            label="Kra Pin"
                            component={TextField}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="phone"
                            type="text"
                            label="Phone Number"
                            component={TextField}
                            fullWidth
                            margin="dense"
                        />
                    </Grid>

                    <Grid item xs={8}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="medium"
                            style={{
                                marginRight: "10px",
                            }}
                        >
                            Ok
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            onClick={handleClose}
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
}

const mapActionsToProps = {
    editMerchant,
};

MerchantEditForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    editMerchant: PropTypes.func.isRequired,
};

export default connect(null, mapActionsToProps)(MerchantEditForm);
