import { InfoWindow, Marker } from "@react-google-maps/api";
import { isEmpty, isLoaded } from "react-redux-firebase";

import Loader from "../../../../../Base/Loader";
import OrderInfoCard from "./OrderInfoCard";
import React from "react";
import packageIcon from "../../../../../../box.svg";
import { useSelector } from "react-redux";

// <img src="https://img.icons8.com/emoji/48/000000/package-.png"/>
function OrderMarkers(props) {
    const { panTo } = props;
    const [selected, setSelected] = React.useState(null);

    const unAssignedSelected = useSelector(
        (state) => state.map.unAssignedSelected
    );

    const showAll = useSelector((state) => state.map.showAll);

    const orders = useSelector((state) => {
        return (
            state.firebase.ordered.AdminDeliveries &&
            state.firebase.ordered.AdminDeliveries.pending_deliveries
        );
    });

    // panTo clicked unAssigned order
    /* eslint-disable  react-hooks/exhaustive-deps */
    React.useEffect(() => {
        if (unAssignedSelected != null) {
            const { receiver_lat, receiver_long } = unAssignedSelected;
            panTo({
                lat: receiver_lat,
                lng: receiver_long,
            });
        } else {
            return "no order to panTo";
        }
    }, [unAssignedSelected]);

    if (!isLoaded(orders)) {
        return <Loader />;
    }

    if (isEmpty(orders)) {
        return null;
    }

    return (
        <div>
            {showAll && (
                <>
                    {orders.map((order) => (
                        <Marker
                            key={order.key}
                            position={{
                                lat: order.value.receiver_lat,
                                lng: order.value.receiver_long,
                            }}
                            icon={{
                                url: packageIcon,
                                scaledSize: new window.google.maps.Size(30, 30),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 15),
                            }}
                            onClick={() => {
                                setSelected(order);
                                console.log("clicked");
                            }}
                        />
                    ))}

                    {/* render info */}
                    {selected ? (
                        <InfoWindow
                            position={{
                                lat: selected.value.receiver_lat,
                                lng: selected.value.receiver_long,
                            }}
                            onCloseClick={() => {
                                setSelected(null);
                            }}
                        >
                            <OrderInfoCard order={selected} />
                        </InfoWindow>
                    ) : null}
                </>
            )}
        </div>
    );
}

export default OrderMarkers;
