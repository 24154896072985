import {
    LOAD_SEARCH_RESULTS,
    FILTER_SEARCH_RESULTS,
    LOAD_MORE_RESULTS,
    // SET_CURRENT_RESULT,
    // LOAD_MORE_RESULTS,
    // CLEAR_SEARCH_RESULTS,
    CLEAR_FILTER_RESULTS,
    SET_CURRENT_CATEGORY,
    CLEAR_CURRENT_CATEGORY,
} from "../types";

const initialState = {
    results: null,
    currentCategory: {
        industryID: 1,
        industry_name: "Accounting",
    },
    filteredResults: null,
    currentResult: null,
    pagingInfo: null,
};

/* eslint-disable import/no-anonymous-default-export */
export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_SEARCH_RESULTS:
            return {
                ...state,
                results: action.payload.results,
                pagingInfo: action.payload.pageInfo,
            };
        case LOAD_MORE_RESULTS:
            return {
                ...state,
                results: [...state.results, ...action.payload.results],
                pagingInfo: action.payload.pageInfo,
            };
        case FILTER_SEARCH_RESULTS:
            return {
                ...state,
                filteredResults: state.results.filter((freelancer) => {
                    const regex = new RegExp(`${action.payload}`, "gi");

                    return (
                        freelancer.user.first_name.match(regex) ||
                        freelancer.user.last_name.match(regex) ||
                        freelancer.customSkills
                            .map((skill) => skill.skill_name)
                            .includes(action.payload)
                    );
                }),
            };

        case CLEAR_FILTER_RESULTS:
            return {
                ...state,
                filteredResults: null,
            };

        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: action.payload,
            };
        case CLEAR_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: null,
            };
        default:
            return state;
    }
}
