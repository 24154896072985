import React from "react";
import PropTypes from "prop-types";
import SummaryCard from "../../../Base/SummaryCard";
import { makeStyles } from "@material-ui/core/styles";
// redux related
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, isLoaded, isEmpty } from "react-redux-firebase";
import Loader from "../../../Base/Loader";
import { get } from "lodash";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

    headerContainer: {
        position: "relative",
        height: "100px",
    },
    header: {
        display: "flex",
        position: "absolute",
        width: "calc(100%)",
        top: "-70px",
        alignItems: "flex-end",
        "& > *": {
            margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        },
    },
    spacer: {
        flexGrow: "1",
    },
    avatar: {
        border: `3px solid white`,
        width: theme.spacing(13),
        height: theme.spacing(13),
        boxShadow: theme.shadows[3],
    },
    actionGroup: {
        display: "flex",
        width: "330px",
        justifyContent: "space-between",
        marginRight: 0,
    },
    summaryCards: {
        display: "flex",
        flexWrap: "wrap",
    },
    summaryCard: {
        margin: theme.spacing(1),
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    tripCard: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },

    fixedHeight: {
        height: 300,
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
}));

const merchantsSuspended = (merchants, state) => {
    return merchants.filter((m) => m.value.is_active === state);
};

function MerchantSummaryCards(props) {
    const { stats } = props;
    const classes = useStyles();

    return (
        <div className={classes.summaryCards}>
            {!isLoaded(stats) ? (
                <Loader />
            ) : isEmpty(stats) ? (
                <>
                    <SummaryCard title={"Merchants Count"} value={0} />
                    <SummaryCard title={"Merchants Active"} value={0} />
                    <SummaryCard title={"Merchants Suspended"} value={0} />
                </>
            ) : (
                <>
                    <SummaryCard
                        title={"Merchants Count"}
                        value={stats.length}
                    />
                    <SummaryCard
                        title={"Merchants Active"}
                        value={merchantsSuspended(stats, true).length}
                    />
                    <SummaryCard
                        title={"Merchants Suspended"}
                        value={merchantsSuspended(stats, false).length}
                    />
                </>
            )}
        </div>
    );
}

MerchantSummaryCards.propTypes = {
    stats: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        stats: get(state.firebase.ordered, "Merchants"),
    };
};

const enhance = compose(
    connect(mapStateToProps, null),
    firebaseConnect([`/Merchants`])
);
export default enhance(MerchantSummaryCards);
