import { LOADING_UI, CLEAR_ERRORS, SET_SUCCESS } from "../types";

import { db } from "../../Utils/fb.config";
import axios from "axios";
import { setErrors } from "./uiActions";

// create store
export const createNewMerchant =
    ({ merchant, closeModal }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        axios
            .post(`/merchants/register`, merchant)
            .then((res) => {
                closeModal();
                dispatch({
                    type: SET_SUCCESS,
                    payload: `merchant created`,
                });
                dispatch({ type: CLEAR_ERRORS });
            })
            .catch((err) => {
                console.log(`merchant Create Error`);
                dispatch(setErrors(err));
            });
    };

export const deleteMerchant =
    ({ id, closeModal }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        axios
            .delete(`/merchants/${id}`)
            .then((res) => {
                closeModal();
                dispatch({
                    type: SET_SUCCESS,
                    payload: `merchant deleted successfully`,
                });
                dispatch({ type: CLEAR_ERRORS });
            })
            .catch((err) => {
                console.log(`merchant Delete Error`);
                dispatch(setErrors(err));
            });
    };

export const editMerchant =
    ({ merchant, closeModal, handleClose, user_id }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        db.ref(`/Merchants/${user_id}/`)
            .update({
                ...merchant,
            })
            .then((value) => {
                handleClose();
                closeModal();
                dispatch({
                    type: SET_SUCCESS,
                    payload: `merchant updated`,
                });
                dispatch({ type: CLEAR_ERRORS });
                // history.push("/dashboard");
            })
            .catch((err) => {
                console.log(`Merchant update Error`);
                dispatch(setErrors(err));
            });
    };

export const suspendUser =
    ({ id, closeModal, disabled }) =>
    (dispatch, getState, { getFirebase }) => {
        dispatch({ type: LOADING_UI });

        console.log(id, "useriiiiiiiiiiiiiiiiiiiiiiiid");

        axios
            .patch(`/merchants/${id}`, { disabled })
            .then((res) => {
                closeModal(); // closes fullscreen modal
                dispatch({
                    type: SET_SUCCESS,
                    payload: `user suspended`,
                });
                dispatch({ type: CLEAR_ERRORS });
            })
            .catch((err) => {
                console.log(`user Suspend Error`);
                dispatch(setErrors(err));
            });
    };
