import Checkbox from "@material-ui/core/Checkbox";
import CustomAssignedUnassignedListComponent from "./CustomAssignedUnassignedListComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { isEmpty } from "react-redux-firebase";
import { makeStyles } from "@material-ui/core/styles";
import CustomMultipleListComponent from "./CustomMultipleListComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "block",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function OrderList(props) {
  const {
    orders,
    handlePanToOrder,
    handleRenderAll,
    handleToggleDetailsView,
    listType,
  } = props;

  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // control rendering of all orders unassigned to map
  const [dense, setDense] = React.useState(false);

  // render all orders that are unassigned
  const renderAll = (event) => {
    setDense(event.target.checked);
    handleRenderAll(event.target.checked);
  };

  /* eslint-disable  eqeqeq */
  const handleListItemClick = (event, index, order) => {
    setSelectedIndex(index);
    // check type
    if (listType == "assigned") {
      handleToggleDetailsView({ order, toggle: true });
    } else if (listType == "unassigned") {
      handlePanToOrder(order);
    }
  };

  // checking for null
  if (isEmpty(orders)) {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="secondary mailbox folder">
          <ListItem
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemText primary="No orders found" />
          </ListItem>
        </List>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {listType == "unassigned" && (
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={dense}
                onChange={(event) => renderAll(event)}
              />
            }
            label="Render All Unassigned Orders"
          />
        </FormGroup>
      )}

      {listType == "multiple" && (
        <CustomMultipleListComponent
          orders={orders}
          selectedIndex={selectedIndex}
          handleListItemClick={handleListItemClick}
          classes={classes}
        />
      )}

      {listType !== "multiple" && (
        <CustomAssignedUnassignedListComponent
          orders={[...orders]}
          selectedIndex={selectedIndex}
          handleListItemClick={handleListItemClick}
          classes={classes}
        />
      )}
    </div>
  );
}
