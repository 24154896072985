import Select, { MySelect } from "./Select";

import ActionButton from "./ActionButton";
import Button from "./Button";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Input from "./Input";
import PasswordInput from "./Password";
import RadioGroup from "./RadioGroup";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    MySelect,
    ActionButton,
    PasswordInput,
};

export default Controls;
