import Content from "../../../Container/Content";
import OrderMap from "./map/OrderMap";
import React from "react";
import { useFirebaseConnect } from "react-redux-firebase";

function OrderContainer(props) {
    useFirebaseConnect([
        {
            path: "AdminDeliveries/pending_deliveries",
            queryParams: ["orderByChild=timestamp"],
        },
        {
            path: "AdminDeliveries/assigned_deliveries",
            queryParams: ["orderByChild=timestamp"],
        },
        {
            path: "AdminDeliveries/multiple_deliveries",
            queryParams: ["orderByChild=timestamp"],
        },
    ]);

    return (
        <Content>
            <OrderMap />
        </Content>
    );
}

export default OrderContainer;
