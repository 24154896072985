import Chip from "@material-ui/core/Chip";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { formatCash } from "../../../../../../../../../Utils/formatDate";

/*eslint-disable eqeqeq*/
/*eslint-disable no-unused-vars*/
export default function ListItemInfo({
  key,
  selectedIndex,
  index,
  handleListItemClick,
  classes,
  value,
  isMultiple,
}) {
  const {
    order_status,
    receiver_address,
    receiver_lat,
    receiver_long,
    order_id,
    order_time,
    receiver_name,
    receiver_phone,
    sender_name,
    sender_phone,
    order_date,
    receiver_id,
    sender_id,
    sender_lat,
    sender_long,
    rider_id = undefined,
    rider_lat = undefined,
    rider_long = undefined,
    rider_email = undefined,
    rider_id_number = undefined,
    rider_plate = undefined,
    rider_image = undefined,
    rider_name = undefined,
    rider_phone = undefined,
    fee_paid_by = undefined,
    receiver_permanent_address = undefined,
    timestamp = undefined,
    delivery_schedule,
    orders,
    delivery_fee,
    ...rest
  } = value;

  return (
    <ListItem
      key={key}
      button
      selected={selectedIndex === index}
      onClick={handleListItemClick}
    >
      <ListItemText
        primary={isMultiple == true ? null : receiver_name}
        secondary={
          <React.Fragment>
            <Typography gutterBottom variant="body2" component="body2">
              Schedule: {delivery_schedule}
            </Typography>

            {delivery_fee && (
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                Delivery Fee {formatCash(parseFloat(delivery_fee))}
              </Typography>
            )}

            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Client Phone: {receiver_phone}
            </Typography>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="h2"
            >
              Merchant: {sender_name}
            </Typography>
            <Chip
              size="small"
              label={`${order_status}`}
              clickable
              color={`${
                order_status == "assigned" || order_status == "assigned rider"
                  ? "primary"
                  : "secondary"
              }`}
            />
          </React.Fragment>
        }
      />
      {delivery_schedule == "INSTANT" && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" color="primary">
            {delivery_schedule == "INSTANT" ? (
              <FlashOnIcon />
            ) : (
              <AccessAlarmIcon />
            )}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
