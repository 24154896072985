import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import {
  dispatchOrder,
  dispatchOrders,
} from "../../../../../../Redux/Actions/basketActions";
import { isEmpty, isLoaded, useFirebaseConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";

import AssignDialog from "../../../../../Base/People/PeopleDialog";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Controls from "../../../../../Controls";
import { Grid } from "@material-ui/core";
import Loader from "../../../../../Base/Loader";
import PropTypes from "prop-types";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { generateOtp } from "../../../../../../Utils/otp";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  progress: {
    position: "absolute",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = Yup.object({
  rider_id: Yup.string().required("please select a rider"),
});

/*eslint-disable eqeqeq*/
const RiderAssignFormik = (props) => {
  const { riders, handleClose, order, orders } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.ui.loading;
  });

  // get orderMap and riderMap objects
  const orderMap = useSelector((state) => state.basket.orderMap);
  const ridersMap = useSelector((state) => state.firebase.data["All Riders"]);
  // check if bulk assignment is multiple orders
  const isActiveMultipleOrders = useSelector(
    (state) => state.basket.isActiveMultipleOrders
  );
  const root_id = useSelector((state) => state.basket.root_id);

  // create options for rider select
  const mapRiders = (arr) => {
    return arr.map((item) => {
      return {
        value: `${item.value.first_name} ${item.value.last_name}`,
        id: item.value.user_id,
      };
    });
  };

  const handleAssign = (values, action) => {
    const { rider_id } = values;

    const {
      email: rider_email,
      first_name: rider_name,
      id_number: rider_id_number,
      number_plate: rider_plate,
      phone_number: rider_phone,
      profileImage: rider_image,
      rider_lat,
      rider_long,
      fcmToken,
    } = ridersMap[rider_id];

    const data = {
      rider_lat,
      rider_long,
      rider_email,
      rider_id_number,
      rider_plate,
      rider_image,
      rider_name,
      rider_phone,
      assigned_at: Date.now(),
      timestamp: Date.now(),
      proof_of_delivery: generateOtp(),
    };

    if (orders) {
      // check if is multiple deliveries
      if (isActiveMultipleOrders == true) {
        dispatch(
          dispatchOrders(
            orderMap,
            rider_id,
            data,
            fcmToken,
            handleClose,
            root_id
          )
        );
      } else {
        dispatch(
          dispatchOrders(orderMap, rider_id, data, fcmToken, handleClose)
        );
      }
    } else {
      let orderUpdated = Object.assign(
        { delivery_type: "single" },
        order,
        data
      );

      console.log(orderUpdated);

      dispatch(dispatchOrder(orderUpdated, rider_id, fcmToken, handleClose));
    }
  };

  return (
    <Formik
      initialValues={{
        rider_id: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleAssign}
    >
      <Form className={classes.form}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.root}>
            <Field
              as={Controls.Select}
              options={mapRiders(riders)}
              name="rider_id"
              type="select"
              label="Rider"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="medium"
              disabled={loading}
            >
              Ok
              {loading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="submit"
              size="medium"
              onClick={handleClose}
              style={{
                marginLeft: "10px",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

const title = "Assign this single package to a rider";

function AssignRiderModal(props) {
  const { order, orders } = props;

  useFirebaseConnect("All Riders");
  const riders = useSelector((state) => {
    return state.firebase.ordered["All Riders"];
  });

  if (!isLoaded(riders)) {
    return <Loader />;
  }

  return (
    <AssignDialog
      title="Assign Rider"
      render={(open) => (
        <Tooltip title={props.title ? props.title : title} placement="bottom">
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={isEmpty(riders)}
            // startIcon={<DeleteIcon />}
            onClick={open}
          >
            {isEmpty(riders) ? "No Riders" : "Assign Rider"}
          </Button>
        </Tooltip>
      )}
    >
      <RiderAssignFormik riders={riders} order={order} orders={orders} />
    </AssignDialog>
  );
}

AssignRiderModal.propTypes = {
  order: PropTypes.object,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      order_status: PropTypes.string,
      receiver_address: PropTypes.string,
      receiver_lat: PropTypes.number,
      receiver_long: PropTypes.number,
      order_id: PropTypes.string,
      order_time: PropTypes.string,
      receiver_name: PropTypes.string,
      receiver_phone: PropTypes.string,
      sender_name: PropTypes.string,
      sender_phone: PropTypes.string,
      order_date: PropTypes.string,
      receiver_id: PropTypes.string,
      sender_id: PropTypes.string,
      receiver_permanent_address: PropTypes.string,
      fee_paid_by: PropTypes.string,
    })
  ),
};

export default AssignRiderModal;
