import {
    SET_FREELANCER_SKILLS,
    FIREBASE_AUTH_ERROR,
    FIREBASE_AUTH_SUCCESS,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    SIGNUP_ERROR,
    CLEAR_SUCCESS,
    PROFILE_DETAIL_ERROR,
    PROFILE_DETAIL_SUCCESS,
    VEHICLE_DETAIL_ERROR,
    VEHICLE_DETAIL_SUCCESS,
} from "../types";

const initialState = {
    signedDriver: false,
    updatePersonal: false,
    firebaseAuthError: null,
    vehicleError: false,
};

/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq*/
export default function (state = initialState, action) {
    switch (action.type) {
        case SIGNOUT_SUCCESS:
            return {
                ...state,
                firebaseAuthError: null,
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                firebaseAuthError: null,
                signedDriver: true,
            };
        case PROFILE_DETAIL_SUCCESS:
            return {
                ...state,
                updatePersonal: true,
                signedDriver: false,
            };
        case VEHICLE_DETAIL_ERROR:
            return {
                ...state,
                vehicleError: false,
            };

        case VEHICLE_DETAIL_SUCCESS:
            return {
                ...state,
                vehicleError: true,
            };
        case PROFILE_DETAIL_ERROR:
            return {
                ...state,
                updatePersonal: false,
            };
        case SIGNUP_ERROR:
            return {
                ...state,
                firebaseAuthError: action.payload,
                signedDriver: false,
            };
        case FIREBASE_AUTH_ERROR:
            return {
                ...state,
                firebaseAuthError: action.payload,
            };

        case FIREBASE_AUTH_SUCCESS:
            return {
                ...state,
                firebaseAuthError: null,
            };
        case SET_FREELANCER_SKILLS:
            return {
                ...state,
                currentFreelancerSkills: action.payload,
            };
        case CLEAR_SUCCESS:
            return {
                signedDriver: false,
                firebaseAuthError: null,
                updatePersonal: false,
                vehicleError: false,
            };
        default:
            return state;
    }
}
