import React from "react";

// redux
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
// form stuff
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { editRider } from "../../../../Redux/Actions/riderActions";

const validationSchema = Yup.object({
  first_name: Yup.string().required("first Name is required"),
  last_name: Yup.string().required("last Name is required"),
  id_number: Yup.number().required("national identification is required"),
  number_plate: Yup.string()
    .max(10, "must not exceed 10 characters")
    .required("number plate eg kcb 145e is required"),
  phone_number: Yup.string()
    .min(10, "must be atleast 10 characters long")
    .max(15, "must not exceed 15 characters")
    .required("phone number is required"),
});

function DriverEditForm(props) {
  const dispatch = useDispatch();

  const {
    closeModal,
    handleClose, //passed by peopleModal via react.clone
    record: {
      value: {
        first_name,
        last_name,
        id_number,
        number_plate,
        phone_number,
        user_id,
      },
    },
  } = props;

  const editCurrentRider = (values, action) => {
    const rider = {
      ...values,
    };
    dispatch(editRider({ rider, closeModal, handleClose, user_id }));
  };

  return (
    <Formik
      initialValues={{
        first_name,
        last_name,
        id_number,
        number_plate,
        phone_number,
      }}
      validationSchema={validationSchema}
      onSubmit={editCurrentRider}
    >
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field
              name="first_name"
              type="text"
              label="First Name"
              component={TextField}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="last_name"
              type="text"
              label="Last Name"
              component={TextField}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="id_number"
              type="number"
              label="ID Number"
              component={TextField}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="phone_number"
              type="text"
              label="Phone Number"
              component={TextField}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="number_plate"
              type="text"
              label="Number Plate"
              component={TextField}
              fullWidth
              margin="dense"
            />
          </Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="medium"
              style={{
                marginRight: "10px",
              }}
            >
              Ok
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={handleClose}
              style={{
                marginLeft: "10px",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

DriverEditForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  editRider: PropTypes.func.isRequired,
};

export default DriverEditForm;
