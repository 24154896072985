import AppBar from "@material-ui/core/AppBar";
import AssignedList from "./orderLists/AssignedList";
import Box from "@material-ui/core/Box";
import MultipleList from "./orderLists/MultipleList"
import PropTypes from "prop-types";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import UnAssignedList from "./orderLists/UnAssignedList";
import { makeStyles } from "@material-ui/core/styles";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        maxHeight: "85vh",
        overflowY: "scroll",
        height: "85vh",
    },
}));

export default function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Assigned" {...a11yProps(0)} />
                    <Tab label="UnAssigned" {...a11yProps(1)} />
                    <Tab label="Multiple" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <AssignedList />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UnAssignedList />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <MultipleList />
            </TabPanel>
        </div>
    );
}
