import React, { useState } from "react";
import {
  deleteRider,
  suspendUser,
} from "../../../../Redux/Actions/riderActions";

import Content from "../../../Container/Content";
import DriverProfile from "./DriverProfile";
// import DriverStats from "./DriverStats";
import DriverSummaryCards from "./DriverSummaryCards";
import DriversTable from "./DriverTables";
import { useDispatch } from "react-redux";

function Driver(props) {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  React.useEffect(() => {
    console.log("i ran kwa ===================== drivers");
  });

  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [record, setRecord] = useState(null);

  //open modal for driver data
  /*eslint-disable react-hooks/exhaustive-deps*/
  const openInPopup = React.useCallback((rider) => {
    setRecord((prev) => ({ ...rider }));
    setOpenPopup(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpenPopup(false);
    setRecord(() => null);
  }, []);

  const handleDriverDelete = React.useCallback(({ id, closeModal }) => {
    dispatch(deleteRider({ id, closeModal }));
  }, []);

  const handleDriverSuspend = React.useCallback(
    ({ id, closeModal, disabled }) => {
      dispatch(
        suspendUser({
          id,
          closeModal,
          disabled,
        })
      );
    },
    []
  );

  return (
    <Content>
      <DriverSummaryCards />
      <DriversTable
        openInPopup={openInPopup}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {record && (
        <>
          <DriverProfile
            record={record}
            openPopup={openPopup}
            handleClose={handleClose}
            handleDelete={handleDriverDelete}
            handleSuspend={handleDriverSuspend}
          />
        </>
      )}
    </Content>
  );
}

export default Driver;
