import CloseIcon from "@material-ui/icons/Close";
import Controls from "../../Controls";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5),
    },
    dialogTitle: {
        paddingRight: "0px",
    },
}));

export default function PeopleDialog({
    title,
    data,
    render,
    onSave,
    children,
}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const handleSave = () => {
    //     // const action = data ? update : add;
    //     // dispatch(action({ name, id: id || nextID(), img }));
    //     onSave && onSave();
    //     handleClose();
    // };

    return (
        <>
            {render(handleClickOpen)}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                classes={{ paper: classes.dialogWrapper }}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}
                >
                    {" "}
                    <div style={{ display: "flex" }}>
                        <Typography
                            variant="h6"
                            component="div"
                            style={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {React.cloneElement(children, { handleClose })}
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {data ? "Close" : "Cancel"}
                    </Button> */}
                {/* <Button onClick={handleSave} color="primary">
                        Save
                    </Button> */}
                {/* </DialogActions> */}
            </Dialog>
        </>
    );
}
