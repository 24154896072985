import {
    TOGGLE_ORDER_DETAILS,
    CLEAR_CURRENT_ORDER,
    SET_CURRENT_ORDER,
    SHOW_ALL_UNASSIGNED,
    UN_ASSIGNED_SELECTED,
} from "../types";

export const toggleOrderDetails =
    ({ order, toggle }) =>
    (dispatch) => {
        dispatch({
            type: SET_CURRENT_ORDER,
            payload: order,
        });
        dispatch({
            type: TOGGLE_ORDER_DETAILS,
            payload: toggle,
        });
    };

export const closeOrderDetails = () => (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_ORDER });
};

export const toggleShowAllUnassigned = (showAll) => (dispatch) => {
    dispatch({ type: SHOW_ALL_UNASSIGNED, payload: showAll });
};

export const unAssignedPanToOrder = (order) => (dispatch) =>
    dispatch({ payload: order, type: UN_ASSIGNED_SELECTED });
