import React from "react";
import Content from "../../../Container/Content";
import HistoryFilter from "./HistoryFilter";
import HistoryTable from "./HistoryTable";

function HistoryPage() {
  return (
    <Content>
      <HistoryFilter />
      <HistoryTable />
    </Content>
  );
}

export default HistoryPage;
