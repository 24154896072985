import {
    toggleShowAllUnassigned,
    unAssignedPanToOrder,
} from "../../../../../../../../Redux/Actions/mapActions";
import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../../../../../../../Base/Loader";
import OrderList from "./OrderList";
import React from "react";
import { isEmpty } from "react-redux-firebase";
import { isLoaded } from "react-redux-firebase";
import { makeStyles } from "@material-ui/core/styles";
import sortedOrders from "./utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: "block",
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

function UnAssignedList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const orders = useSelector((state) => {
        return (
            state.firebase.ordered.AdminDeliveries &&
            state.firebase.ordered.AdminDeliveries.pending_deliveries
        );
    });

    if (!isLoaded(orders)) {
        return <Loader />;
    }

    const handleRenderAll = (showAll) => {
        dispatch(toggleShowAllUnassigned(showAll));
    };

    const handlePanToOrder = (order) => {
        dispatch(unAssignedPanToOrder(order));
    };

    if (isEmpty(orders)) {
        return (
            <div className={classes.root}>
                <List component="nav" aria-label="secondary mailbox folder">
                    <ListItem button>
                        <ListItemText primary="No orders found" />
                    </ListItem>
                </List>
            </div>
        );
    }

    return (
        <OrderList
            orders={sortedOrders(orders)}
            handlePanToOrder={handlePanToOrder}
            listType="unassigned"
            handleRenderAll={handleRenderAll}
        />
    );
}

export default UnAssignedList;
