import {
  LOADING_UI,
  LOAD_HISTORY_ORDERS,
  LOAD_MORE_QWIB_MERCHANT_COMMISSIONS,
  LOAD_QWIB_MERCHANT_COMMISSIONS,
  SET_COMMISSION_TRIPS_RIDERS,
  SET_ERRORS,
  SET_QWIB_ORDERS,
  SET_QWIB_SALES,
  SET_QWIB_STATS,
  SET_RIDER_STATS,
  SET_SUCCESS,
} from "../types";

import axios from "axios";

function start_date_end_date(num_days = 30) {
  let daysback = num_days;
  var today = new Date();

  var priorDate = new Date().setDate(today.getDate() - daysback);
  var start_date = new Date(priorDate).toISOString();

  var tommorrow_date = new Date().setDate(today.getDate() + 1);
  var end_date = new Date(tommorrow_date).toISOString();

  console.log(start_date, end_date, "========**************============");

  return [start_date, end_date];
}

export function start_date_end_date_from_iso(start_date, end_date) {
  var _start_date = start_date.toISOString();

  var tommorrow_date = end_date.setDate(end_date.getDate() + 1);
  var _end_date = new Date(tommorrow_date).toISOString();

  console.log(_start_date, "================================", _end_date);

  return [_start_date, _end_date];
}

export const fetchDashboardStats =
  (nums = 30) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
    };

    axios
      .post(`/reports/stats`, data)
      .then((res) => {
        console.log(JSON.stringify(res.data, null, 2));
        dispatch({ type: SET_QWIB_STATS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_QWIB_STATS,
          payload: {
            totalOrders: 0,
            totalTrips: 0,
            commission: 0,
          },
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

export const fetchordersPerDayStats =
  (nums = 30) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
    };

    axios
      .post(`/reports/ordersPerDay`, data)
      .then((res) => {
        dispatch({ type: SET_QWIB_ORDERS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Orders stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_QWIB_ORDERS,
          payload: [
            {
              day: new Date().toISOString(),
              count: 0,
            },
          ],
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

export const fetchsalesLast30DayStats =
  (nums = 30) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(nums);
    const data = {
      start_date,
      end_date,
    };

    axios
      .post(`/reports/salesPerDay`, data)
      .then((res) => {
        dispatch({ type: SET_QWIB_SALES, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Sales stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_QWIB_SALES,
          payload: [
            {
              day: new Date().toISOString(),
              total_income: 0,
            },
          ],
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// riders commissions
export const commissionFeeByRiders =
  ({ limit = 50, nextPage = "" }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    console.log("i ranaaan");

    const data = {
      limit,
    };

    axios
      .post(`/reports/commissionFeeByRiders`, data)
      .then((res) => {
        dispatch({
          type: SET_COMMISSION_TRIPS_RIDERS,
          payload: res.data,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: `Riders commissions fetched successfully`,
        });
        // history.push("/jobs");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// merchant commissions
export const commissionFeeByMerchant =
  ({ limit = 2, nextPage = "" }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const data = {
      limit,
    };

    axios
      .post(`/reports/commissionFeeByMerchant`, data)
      .then((res) => {
        dispatch({
          type: LOAD_QWIB_MERCHANT_COMMISSIONS,
          payload: res.data,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: `Merchant commissions fetched successfully`,
        });
        // history.push("/jobs");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// TODO load more
export const LoadMoreCommissionFeeByMerchant =
  ({ limit, nextPage }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const data = {
      limit,
      nextPage,
    };

    axios
      .post(`/reports/commissionFeeByMerchant`, data)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: LOAD_MORE_QWIB_MERCHANT_COMMISSIONS,
          payload: res.data,
        });
        dispatch({
          type: SET_SUCCESS,
          payload: `More Merchants fetched successfully`,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// riders reports

export const fetchRiderStats =
  (rider_id, num_days = 30) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const [start_date, end_date] = start_date_end_date(num_days);
    const data = {
      start_date,
      end_date,
      rider_id,
    };

    axios
      .post(`/reports/riderStats`, data)
      .then((res) => {
        dispatch({ type: SET_RIDER_STATS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Stats fetched successfully`,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_RIDER_STATS,
          payload: {
            totalOrders: 0,
            totalTrips: 0,
            commission: 0,
          },
        });

        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };

// riders reports

export const fetchAllOrders =
  ({
    limit = 100,
    _start_date: start_date,
    _end_date: end_date,
    nextPage = "",
    order_status = "Delivered",
  }) =>
  (dispatch) => {
    dispatch({ type: LOADING_UI });

    const data = {
      start_date,
      end_date,
      order_status,
      limit,
      nextPage,
    };

    axios
      .post(`/reports/allOrders`, data)
      .then((res) => {
        dispatch({ type: LOAD_HISTORY_ORDERS, payload: res.data });
        dispatch({
          type: SET_SUCCESS,
          payload: `Orders history fetched successfully`,
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
          });
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: err,
          });
        }
      });
  };
