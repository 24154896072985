import CompanyReg from "./Components/Pages/Auth/Register/SImpleCompanyReg/CompanyReg";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import HistoryIcon from "@material-ui/icons/History";
import Driver from "./Components/Pages/Qwib/Drivers/Driver";
import DriverRegister from "./Components/Pages/Auth/Register/SImpleCompanyReg/DriverRegister";
import Login from "./Components/Pages/Auth/Login/Login";
import Merchants from "./Components/Pages/Qwib/Merchants/Merchants";
import Order from "./Components/Pages/Qwib/Orders/Order";
import RegisterPage from "./Components/Pages/Auth/Register/SImpleCompanyReg/RegisterPage";
import HistoryPage from "./Components/Pages/Qwib/History";
// customer company routes
import Reports from "./Components/Pages/Qwib/Reports/Reports";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SportsMotorsportsIcon from "@material-ui/icons/SportsMotorsports";
import StorefrontIcon from "@material-ui/icons/Storefront";

export const defaultRoutes = [
  {
    path: "/register",
    exact: true,
    component: RegisterPage,
  },
  {
    path: "/store-registration",
    exact: true,
    component: CompanyReg,
  },
  {
    path: "/driver-registration",
    exact: true,
    component: DriverRegister,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
];

const dashboardRoutes = [
  {
    path: "reports",
    component: Reports,
    text: "reports",
    icon: DataUsageIcon,
    layout: "/company",
  },

  {
    path: "merchants",
    component: Merchants,
    text: "merchants",
    icon: StorefrontIcon,
    layout: "/admin",
  },
  {
    path: "riders",
    component: Driver,
    text: "riders",
    icon: SportsMotorsportsIcon,
    layout: "/admin",
  },
  {
    path: "orders",
    component: Order,
    text: "orders",
    icon: ShoppingBasketIcon,
    layout: "/admin",
  },

  {
    path: "history",
    component: HistoryPage,
    text: "history",
    icon: HistoryIcon,
    layout: "/admin",
  },

  // {
  //     path: "orders",
  //     component: JobsContainer,
  //     text: "orders",
  //     icon: ShoppingBasketIcon,
  //     layout: "/company",

  //     routes: [
  //         {
  //             path: "",
  //             exact: true,
  //             component: Jobs,
  //             text: "stores",
  //         },

  //         {
  //             path: ":job_id/details",
  //             component: BranchDetails,
  //         },

  //         {
  //             path: ":id/products",
  //             component: Products,
  //         },
  //     ],
  // },
  // {
  //     path: "search",
  //     component: Search,
  //     text: "search",
  //     icon: SearchIcon,
  //     layout: "/company",
  // },
  // {
  //     path: "profile",
  //     component: UserProfile,
  //     text: "profile",
  //     icon: AccountCircleIcon,
  //     layout: "/company",
  // },
];

export default dashboardRoutes;
