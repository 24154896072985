import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
//mui stuff
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadButton from "../../../../../Base/UploadButton";
import useImageHandler from "../../../../../../Hooks/useImageHandler";
import ProgressBar from "../../../../../Base/ProgressBarImages";

import { updateDriver } from "../../../../../../Redux/Actions/driverActions";

const validationSchemaCompany = Yup.object({
    phoneNumber: Yup.string()
        .required("phoneNumber is required")
        .min(10)
        .max(15),
    nationalIDNumber: Yup.string().required("National ID is required").max(10),
});

function Page1AuthDetails(props) {
    const {
        classes,
        loading,
        updateDriver,
        auth,
        nextStep,
        updatePersonal,
    } = props;

    const {
        file,
        setFile,
        imageUrl,
        setImageUrl,
        imageChangeHandler,
    } = useImageHandler();

    const handleSubmit = (values, actions) => {
        const { phoneNumber, nationalIDNumber } = values;

        const driverData = {
            phoneNumber,
            nationalIDNumber,
            profileImage: imageUrl ?? " ",
        };

        updateDriver({ uid: auth.uid, driverData, action: "personalDetails" });
    };

    return (
        <>
            {imageUrl ? (
                <Avatar className={classes.imageUrl} src={imageUrl} />
            ) : (
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
            )}
            <Typography component="h1" variant="h5">
                Personal Details (2)
            </Typography>

            <Formik
                initialValues={{
                    phoneNumber: "",
                    nationalIDNumber: "",
                }}
                validationSchema={validationSchemaCompany}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
                <Form>
                    <Field
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        variant="outlined"
                        component={TextField}
                        fullWidth
                    />

                    <Field
                        name="nationalIDNumber"
                        type="number"
                        label="National ID"
                        variant="outlined"
                        component={TextField}
                        fullWidth
                    />

                    {file == null && (
                        <UploadButton
                            imageChangeHandler={imageChangeHandler}
                            title={
                                file == null
                                    ? "Upload Profile Picture"
                                    : "Profile Image Uploaded Successfully"
                            }
                        />
                    )}

                    {file && (
                        <ProgressBar
                            file={file}
                            setFile={setFile}
                            setImageUrl={setImageUrl}
                        />
                    )}

                    <div>
                        {updatePersonal ? (
                            <Button
                                disabled={!updatePersonal}
                                variant="contained"
                                color="secondary"
                                fullWidth
                                className={classes.submit}
                                onClick={() => nextStep()}
                            >
                                Next
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                className={classes.submit}
                                type="submit"
                                fullWidth
                            >
                                Submit Details
                                {loading && (
                                    <CircularProgress
                                        size={30}
                                        className={classes.progress}
                                    />
                                )}
                            </Button>
                        )}
                    </div>
                </Form>
            </Formik>
        </>
    );
}

Page1AuthDetails.propTypes = {
    updateDriver: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    updatePersonal: state.driver.updatePersonal,
});

const mapActionsToProps = {
    updateDriver,
};

export default connect(mapStateToProps, mapActionsToProps)(Page1AuthDetails);
