function newDate(arr, prefix) {
  const year = Number(`20${arr[0]}`);
  const month = Number(arr[1]);
  const day = Number(arr[2]);
  const hours = numFromPrefix(prefix, Number(arr[3]));
  const minutes = Number(arr[4]);
  const seconds = Number(arr[5]);

  const new_date = new Date(year, month, day, hours, minutes, seconds);

  return new_date.getTime();
}

function numFromPrefix(prefix, num) {
  /* eslint eqeqeq: "off", curly: "error" */
  if (prefix == "am") {
    // return as is
    return num;
  }

  // add 12

  return num + 12;
}

function timePrefix(order_time) {
  if (order_time.includes("am")) {
    return "am";
  }

  return "pm";
}

export default function sortedOrder(orders) {
  return orders.sort((a, b) => {
    console.log(JSON.stringify(b, null, 2), "bbbbbbbbbbbbbbbbbbbbbb");
    const {
      value: { order_id: a_order_id, order_time: a_order_time },
    } = a;
    const {
      value: { order_id: b_order_id, order_time: b_order_time },
    } = b;

    // extract custom timestamp
    //210816022719819011628d6-a426-4c25-8b34-556cb1ecd5b2
    //210816022719819011628

    const b_str_time = b_order_id.substring(
      0,
      b_order_id.indexOf(b_order_id.match(/[a-z]/i)[0])
    );

    const a_str_time = a_order_id.substring(
      0,
      a_order_id.indexOf(a_order_id.match(/[a-z]/i)[0])
    );

    // timestamp arr
    // ['21','08'.'16', ...rest]
    const b_arr = b_str_time.match(/.{1,2}/g);
    const a_arr = a_str_time.match(/.{1,2}/g);

    const a_date = newDate(a_arr, timePrefix(a_order_time));
    const b_date = newDate(b_arr, timePrefix(b_order_time));

    return b_date - a_date;
  });
}

export function formatOrdersToFirebaseArray(ordersObj) {
  const orders = [];

  /**
   * [
   * {
   * "key": "some key",
   * "value": {
   * order_status: "pending"
   * }}]
   */

  for (const [key, valueObj] of Object.entries(ordersObj)) {
    //here value will be the object
    console.log(`${key}: ${valueObj}`);

    const data = {
      key,
      value: {
        ...valueObj,
      },
    };

    orders.push(data);
  }

  return orders;
}

export function formatOrdersObjArray(ordersObj) {
  const orders = [];
  // takes an obj of objs and flattens it
  /**
   * [
   * {
   * "key": "some key",
   * order_status: "pending"
   * }]
   */

  for (const [key, valueObj] of Object.entries(ordersObj)) {
    //here value will be the object
    console.log(`${key}: ${valueObj}`);

    const data = {
      order_id: key,
      ...valueObj,
    };

    orders.push(data);
  }

  return orders;
}

export function extractOrdersFromMultipleArray(ordersObj) {
  const _orders = [];
  // takes an obj of objs and flattens it
  /**
    *  {
    "key": "-Mm5KZqNpUjLBqhLcNV2",
    "value": {
      "orders": {
        "y7rycbsihe7333": {
          "delivery_fee": "150",
          "delivery_schedule": "INSTANT",
          "fee_paid_by": "Merchant",
          "order_date": "Aug 12, 2021",
          "order_id": "y7rycbsihe7333",
          "order_status": "pending",
          "order_time": "13:41:54 PM",
          "proof_of_delivery": "gNat3n",
          "receiver_address": "Mall Apartments",
          "receiver_id": "string",
          "receiver_lat": 0,
          "receiver_long": 0,
          "receiver_name": "Merchant x",
          "receiver_permanent_address": "string",
          "receiver_phone": "+254714382366",
          "sender_id": "string",
          "sender_lat": 0,
          "sender_long": 0,
          "sender_name": "string",
          "sender_phone": "+254714382366",
          "timestamp": 0,
          "tracking_code": "5424"
        }
      }
    }
  }
    */

  for (const {
    key,
    value: { orders },
  } of ordersObj) {
    for (const [innerkey, valueObj] of Object.entries(orders)) {
      //here value will be the object
      console.log(`${innerkey}: ${valueObj}`);

      const data = {
        key,
        order_id: innerkey,
        ...valueObj,
      };

      _orders.push(data);
    }
  }

  return _orders;
}
