import React, { useState } from "react";
import Content from "../../../Container/Content";
import MerchantsTable from "./MerchantsTable";
import MerchantProfile from "./MerchantProfile";
import MerchantSummaryCards from "./MerchantSummaryCard";
import { useSelector } from "react-redux";
import Loader from "../../../Base/Loader";
import {
  deleteMerchant,
  suspendUser,
} from "../../../../Redux/Actions/merchantActions";

function Merchants(props) {
  const loading = useSelector((state) => state.ui.loading);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [record, setRecord] = useState(null);

  const handleClose = React.useCallback(() => {
    setOpenPopup(false);
    setRecord(() => null);
  }, []);

  // modal for merchant profile to prevent re-renders;
  const openInPopup = React.useCallback((merchant) => {
    setRecord((prev) => ({ ...merchant }));
    setOpenPopup(true);
  }, []);

  if (loading) {
    return (
      <Content>
        <Loader />
      </Content>
    );
  }

  return (
    <Content>
      <MerchantSummaryCards />
      <MerchantsTable
        openInPopup={openInPopup}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {record && (
        <MerchantProfile
          record={record}
          openPopup={openPopup}
          handleClose={handleClose}
          handleDelete={deleteMerchant}
          handleSuspend={suspendUser}
        />
      )}
    </Content>
  );
}

export default React.memo(Merchants);
