import React, { useState } from "react";
import Content from "../../../../Container/Content";
import DriverRegisterFormLayout from "./DriverRegister/DriverRegisterFormLayout";
import { Step, StepLabel, Stepper } from "@material-ui/core";

import Page1 from "./DriverRegister/page1AuthDetails";
import Page2 from "./DriverRegister/page2PersonalDetails";
import Page3 from "./DriverRegister/page3VehicleDetails";
import Page4 from "./DriverRegister/page4Success";

const childrenArray = [<Page1 />, <Page2 />, <Page3 />, <Page4 />];

function DriverRegister(props) {
    const [step, setStep] = useState(0);
    const [completed] = useState(false);

    const currentChild = childrenArray[step];

    // function isLastStep() {
    //     return step == childrenArray.length - 1;
    // }

    function nextStep() {
        setStep((s) => s + 1);
    }

    return (
        <Content>
            <Stepper alternativeLabel activeStep={step}>
                {childrenArray.map((child, index) => (
                    <Step
                        key={child.props.label}
                        completed={step > index || completed}
                    >
                        <StepLabel>{child.props.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <DriverRegisterFormLayout nextStep={nextStep}>
                {currentChild}
            </DriverRegisterFormLayout>
        </Content>
    );
}

export default DriverRegister;
