import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    summaryCards: {
        display: "flex",
        flexWrap: "wrap",
    },
    titleWithAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    summaryCard: {
        margin: theme.spacing(1),
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function SummaryCard({
    title,
    value,
    component,
    titleWithAction,
}) {
    const classes = useStyles();
    return (
        <Paper elevation={2} className={classes.summaryCard}>
            <div className={classes.titleWithAction}>
                <Typography color={"textSecondary"} variant="h5" gutterBottom>
                    {title}
                </Typography>
                {titleWithAction && titleWithAction}
            </div>
            {component || (
                <Typography color={"primary"} variant="h3">
                    {value}
                </Typography>
            )}
        </Paper>
    );
}
