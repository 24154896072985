import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { connect } from "react-redux";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
//mui stuff
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { signUpDriver } from "../../../../../../Redux/Actions/driverActions";

const validationSchemaCompany = Yup.object({
    name: Yup.string().required("fullname is required"),
    password: Yup.string()
        .min(8)
        .max(200)
        .matches(/[^A-Za-z0-9]/, "password must contain a special character")
        .matches(/[A-Z]/, "password must contain an uppercase letter")
        .matches(/[a-z]/, "password must contain a lowercase letter")
        .matches(/[0-9]/, "password must contain a number")
        .required(),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
    email: Yup.string().email("invalid companyEmail address").required(),
});

function Page1AuthDetails(props) {
    const { classes, loading, signUpDriver, nextStep, signedDriver } = props;

    const handleSubmit = (values, actions) => {
        const { name, email, password } = values;

        signUpDriver({ name, email, password });
    };

    return (
        <>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
                Driver Auth Details (1)
            </Typography>

            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                }}
                validationSchema={validationSchemaCompany}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
                <Form>
                    <Field
                        name="name"
                        type="text"
                        label="Full Name"
                        variant="outlined"
                        component={TextField}
                        fullWidth
                    />

                    <Field
                        name="email"
                        type="text"
                        label="Email"
                        variant="outlined"
                        component={TextField}
                        autoComplete="email"
                        fullWidth
                    />

                    <Field
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                        component={TextField}
                        fullWidth
                    />
                    <Field
                        name="confirmPassword"
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                        component={TextField}
                        fullWidth
                    />

                    <div>
                        {signedDriver ? (
                            <Button
                                disabled={!signedDriver}
                                variant="contained"
                                fullWidth
                                color="secondary"
                                onClick={() => nextStep()}
                            >
                                Next
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                className={classes.submit}
                                type="submit"
                            >
                                Create Account
                                {loading && (
                                    <CircularProgress
                                        size={30}
                                        className={classes.progress}
                                    />
                                )}
                            </Button>
                        )}
                    </div>
                </Form>
            </Formik>
        </>
    );
}

Page1AuthDetails.propTypes = {
    signUpDriver: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.ui.loading,
    auth: state.firebase.auth,
    signedDriver: state.driver.signedDriver,
});

const mapActionsToProps = {
    signUpDriver,
};

export default connect(mapStateToProps, mapActionsToProps)(Page1AuthDetails);
