// redux related
import { useDispatch, useSelector } from "react-redux";

import ChartSummary from "./ChartSummary";
import Loader from "../../../Base/Loader";
import React from "react";
import VerticonOptions from "../../../Base/VerticonOptions";
import { fetchsalesLast30DayStats } from "../../../../Redux/Actions/reportActions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  headerContainer: {
    position: "relative",
    height: "100px",
  },
  header: {
    display: "flex",
    position: "absolute",
    width: "calc(100%)",
    top: "-70px",
    alignItems: "flex-end",
    "& > *": {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  spacer: {
    flexGrow: "1",
  },
  avatar: {
    border: `3px solid white`,
    width: theme.spacing(13),
    height: theme.spacing(13),
    boxShadow: theme.shadows[3],
  },
  actionGroup: {
    display: "flex",
    width: "330px",
    justifyContent: "space-between",
    marginRight: 0,
  },
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  fixedHeight: {
    height: 300,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function SalesChart() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState("Sales last 30 days");

  const _Today = () => {
    setLabel("Sales Today");
    dispatch(fetchsalesLast30DayStats(1));
  };

  const _7days = () => {
    setLabel("Sales last 7 days");
    dispatch(fetchsalesLast30DayStats(7));
  };

  const _14days = () => {
    setLabel("Sales last 14 days");
    dispatch(fetchsalesLast30DayStats(14));
  };

  const _30days = () => {
    setLabel("Sales last 30 days");
    dispatch(fetchsalesLast30DayStats(30));
  };

  const options = [
    {
      name: "Today",
      onClick: _Today,
    },
    {
      name: "7 days",
      onClick: _7days,
    },
    {
      name: "14 days",
      onClick: _14days,
    },
    {
      name: "30 days",
      onClick: _30days,
    },
  ];

  const salesObj = useSelector((state) => {
    return state.report.salesStats;
  });

  React.useEffect(() => {
    dispatch(fetchsalesLast30DayStats());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (salesObj.loading) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  return (
    <ChartSummary
      data={salesObj.sales}
      value="total_income"
      date="day"
      label={label}
      currency={true}
      component={<VerticonOptions options={options} />}
      options={true}
    />
  );
}

export default SalesChart;
