import { SET_ERRORS, CLEAR_ERRORS } from "../types";

export const errorAlert = (payload) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        payload,
    });

    dispatch({ type: CLEAR_ERRORS });
};

// helper function
export const setErrors = (err) => (dispatch) => {
    if (err.response) {
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data,
        });
    } else {
        dispatch({
            type: SET_ERRORS,
            payload: err,
        });
    }
};
