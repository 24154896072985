import React from "react";
import PropTypes from "prop-types";
import SummaryCard from "../../../Base/SummaryCard";
import { makeStyles } from "@material-ui/core/styles";
// redux related
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, isLoaded, isEmpty } from "react-redux-firebase";
import Loader from "../../../Base/Loader";
import { get } from "lodash";

const ridersOnline = (riders, state) => {
    return riders.filter((r) => r.value.status === state);
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

    headerContainer: {
        position: "relative",
        height: "100px",
    },
    header: {
        display: "flex",
        position: "absolute",
        width: "calc(100%)",
        top: "-70px",
        alignItems: "flex-end",
        "& > *": {
            margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        },
    },
    spacer: {
        flexGrow: "1",
    },
    avatar: {
        border: `3px solid white`,
        width: theme.spacing(13),
        height: theme.spacing(13),
        boxShadow: theme.shadows[3],
    },
    actionGroup: {
        display: "flex",
        width: "330px",
        justifyContent: "space-between",
        marginRight: 0,
    },
    summaryCards: {
        display: "flex",
        flexWrap: "wrap",
    },
    summaryCard: {
        margin: theme.spacing(1),
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    tripCard: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },

    fixedHeight: {
        height: 300,
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
}));

function DriverSummaryCards(props) {
    const { riders } = props;
    const classes = useStyles();

    if (!isLoaded(riders)) {
        return <Loader />;
    }

    console.log(riders);

    return (
        <div className={classes.summaryCards}>
            {!isLoaded(riders) ? (
                <Loader />
            ) : isEmpty(riders) ? (
                <>
                    <SummaryCard title={"Riders Count"} value={0} />
                    <SummaryCard title={"Riders Online"} value={0} />
                    <SummaryCard title={"Riders Offline"} value={0} />
                </>
            ) : (
                <>
                    <SummaryCard title={"Riders Count"} value={riders.length} />
                    <SummaryCard
                        title={"Riders Online"}
                        value={ridersOnline(riders, "online").length}
                    />
                    <SummaryCard
                        title={"Riders Offline"}
                        value={ridersOnline(riders, "offline").length}
                    />
                </>
            )}
        </div>
    );
}

DriverSummaryCards.propTypes = {
    riders: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        riders: get(state.firebase.ordered, "All Riders"),
    };
};

const enhance = compose(
    connect(mapStateToProps, null),
    firebaseConnect([`All Riders`])
);
export default enhance(DriverSummaryCards);
