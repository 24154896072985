import { isEmpty, isLoaded } from "react-redux-firebase";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../../../../../../../Base/Loader";
import OrderList from "./OrderList";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import sortedOrders from "./utils";
import { toggleOrderDetails } from "../../../../../../../../Redux/Actions/mapActions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function AssignedList() {
  const classes = useStyles();
  const orders = useSelector((state) => {
    return (
      state.firebase.ordered.AdminDeliveries &&
      state.firebase.ordered.AdminDeliveries.assigned_deliveries
    );
  });

  const dispatch = useDispatch();

  const handleToggleDetailsView = ({ order, toggle }) => {
    dispatch(toggleOrderDetails({ order, toggle }));
  };

  if (!isLoaded(orders)) {
    return <Loader />;
  }

  if (isEmpty(orders)) {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="secondary mailbox folder">
          <ListItem button>
            <ListItemText primary="No orders found" />
          </ListItem>
        </List>
      </div>
    );
  }

  return (
    <OrderList
      orders={sortedOrders(orders)}
      handleToggleDetailsView={handleToggleDetailsView}
      listType="assigned"
    />
  );
}

export default AssignedList;
