import auth from "./authReducer";
import basket from "./basketReducer";
import { combineReducers } from "redux";
import driver from "./driverReducer";
import { firebaseReducer as firebase } from "react-redux-firebase";
import freelancer from "./freelancerReducer";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import industry from "./industriesReducer";
import jobApplicants from "./jobApplicantsReducer";
import jobs from "./jobsReducer";
import localStorage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import locationReducer from "./locationReducer";
import map from "./mapReducer";
import { persistReducer } from "redux-persist";
import proposal from "./proposalReducer";
import report from "./reportsReducer";
import search from "./searchReducer";
import status from "./statusReducer";
import ui from "./uiReducer";

const rootReducer = combineReducers({
    auth,
    ui,
    jobApplicants,
    industry,
    jobs,
    proposal,
    status,
    freelancer,
    report,
    search,
    driver,
    firebase: persistReducer(
        {
            key: "firebaseState",
            storage: localStorage,
            stateReconciler: hardSet,
        },
        firebase
    ),
    location: locationReducer,
    basket,
    map,
});

// eslint-disable-next-line
export default (state, action) =>
    rootReducer(
        action.type === "SET_UNAUTHENTICATED" ? undefined : state,
        action
    );
