import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { red } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import { formatOrdersToFirebaseArray } from "./utils";
import OrderList from "./OrderList";
import { unAssignedPanToOrder } from "../../../../../../../../Redux/Actions/mapActions";
import { useDispatch } from "react-redux";
import C from "./customMultipleList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 360,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontSize: 14,
  },
  nested: {
    paddingLeft: theme.spacing(1.5),
  },
}));

/*eslint-disable no-unused-vars*/
/*eslint-disable eqeqeq*/
function OrderListItem({ ordersObj, isNested, multipleOrderKey }) {
  const dispatch = useDispatch();

  const handleRenderAll = (showAll) => {};

  const handlePanToOrder = (order) => {
    dispatch(unAssignedPanToOrder(order));
  };

  return (
    <OrderList
      orders={formatOrdersToFirebaseArray({ ...ordersObj })}
      handlePanToOrder={handlePanToOrder}
      listType="unassigned"
      isNested={isNested}
      multipleOrderKey={multipleOrderKey}
      isMultiple={true}
      handleRenderAll={handleRenderAll}
    />
  );
}

/*eslint-disable no-unused-vars*/

export default function CustomMultipleListComponent({
  orders: upperOrders,
  selectedIndex,
  handleListItemClick,
  isMultiple = true,
}) {
  const classes = useStyles();

  return (
    <List component="nav" aria-label="orders">
      {upperOrders.map(({ key, value: { orders } }, index) => (
        <>
          <C.ListCollapse
            classes={classes}
            selectedIndex={selectedIndex}
            index={index}
            orders={{ ...orders }}
            multipleOrderKey={key}
            handleListItemClick={handleListItemClick}
            isMultiple={isMultiple}
          />
        </>
      ))}
    </List>
  );
}
