import {
  LoadMoreCommissionFeeByMerchant,
  commissionFeeByMerchant,
} from "../../../../Redux/Actions/reportActions";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../Controls/Button";
import { DataGrid } from "@material-ui/data-grid";
import Loader from "../../../Base/Loader";
import React from "react";
import Title from "../../../Base/Title";
import { formatCash } from "../../../../Utils/formatDate";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const columns = [
  { field: "id", headerName: "ID", width: 78 },
  { field: "sender_name", headerName: "Merchant name", width: 200 },
  { field: "total", headerName: "Transactions", width: 200 },
];

function createData(idx, sender_name, total) {
  return {
    id: idx + 1,
    sender_name,
    total,
  };
}

function createRows(rows) {
  const arr = [];
  rows.forEach(({ sender_name, total }, index) => {
    arr.push(createData(index, sender_name, formatCash(total)));
  });

  return arr;
}

function CommissionsTablePerMerchant() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rowsObj = useSelector((state) => state.report.merchantsCommissions);

  React.useEffect(() => {
    if (rowsObj.results == null) {
      dispatch(commissionFeeByMerchant({ limit: 100, nextPage: "" }));
    }
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  const loadMore = () => {
    dispatch(
      LoadMoreCommissionFeeByMerchant({
        limit: 50,
        nextPage: rowsObj.pageInfo.next || "",
      })
    );
  };

  if (rowsObj.loading) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <>
        {rowsObj.results == null ? (
          <Title>No Data to display</Title>
        ) : (
          <>
            <Title>Merchant Stats</Title>
            <DataGrid
              rows={createRows(rowsObj.results)}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5, 10, 15, 25, 50]}
              checkboxSelection
              disableSelectionOnClick
            />
            {rowsObj.pageInfo.hasNext && (
              <Button text={"Load More"} onClick={loadMore} gutterBottom />
            )}
          </>
        )}
      </>
    </div>
  );
}

export default CommissionsTablePerMerchant;
