import { formatOrdersObjArray } from "../../Components/Pages/Qwib/Orders/map/orderListContainer/tabsAndList/orderLists/utils";
import {
  ADD_TO_BASKET,
  CLEAR_BASKET,
  REMOVE_FROM_BASKET,
  ADD_MULTIPLE_TO_BASKET,
  ADD_ROOT_ID,
} from "../types";

const initialState = {
  orders: [],
  orderMap: {}, // will hold keys for the orders to prevent duplications
  isActiveMultipleOrders: false,
  root_id: null,
};

const basketReducer = function (state = initialState, action) {
  switch (action.type) {
    case ADD_TO_BASKET:
      state.orderMap[action.payload.order_id] = action.payload;
      return {
        ...state,
        orders: [...state.orders, action.payload],
      };

    case ADD_ROOT_ID:
      return {
        ...state,
        root_id: action.payload,
      };

    case ADD_MULTIPLE_TO_BASKET:
      return {
        ...state,
        orders: formatOrdersObjArray({ ...action.payload.ordersObj }),
        root_id: action.payload.root_id,
        orderMap: action.payload.ordersObj,
        isActiveMultipleOrders: true,
      };

    case CLEAR_BASKET:
      return {
        orders: [],
        orderMap: {},
        isActiveMultipleOrders: false,
        root_id: null,
      };

    case REMOVE_FROM_BASKET:
      const id = action.payload;
      delete state.orderMap[id];
      return {
        ...state,
        orders: [
          ...state.orders.filter((order) => {
            return order.order_id !== id;
          }),
        ],
      };

    default:
      return state;
  }
};

export default basketReducer;
