import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {
    isDuplicate,
    isDuplicate2,
} from "../../../../../../Redux/Actions/basketActions";
import { useDispatch, useSelector } from "react-redux";

function AddToBasket(props) {
    const dispatch = useDispatch();
    const { order } = props;
    const orderMap = useSelector((state) => state.basket.orderMap);

    const handleAddToBasket = (order) => {
        dispatch(isDuplicate(orderMap, order.order_id, order));
        return;
    };

    return (
        <Tooltip
            title={"Add multiple packages to one basket and assign to a rider"}
            placement="bottom"
        >
            <Button
                color="secondary"
                variant="contained"
                size="small"
                disabled={isDuplicate2(orderMap, order.order_id)}
                // startIcon={<DeleteIcon />}
                onClick={() => handleAddToBasket(order)}
            >
                {isDuplicate2(orderMap, order.order_id)
                    ? "package in basket"
                    : "Add To Basket"}
            </Button>
        </Tooltip>
    );
}

export default AddToBasket;
