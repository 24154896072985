// redux related
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../Base/Loader";
import React from "react";
import SummaryCard from "../../../Base/SummaryCard";
import VerticonOptions from "../../../Base/VerticonOptions";
import { fetchDashboardStats } from "../../../../Redux/Actions/reportActions";
import { formatCash } from "../../../../Utils/formatDate";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  headerContainer: {
    position: "relative",
    height: "100px",
  },
  header: {
    display: "flex",
    position: "absolute",
    width: "calc(100%)",
    top: "-70px",
    alignItems: "flex-end",
    "& > *": {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  spacer: {
    flexGrow: "1",
  },
  avatar: {
    border: `3px solid white`,
    width: theme.spacing(13),
    height: theme.spacing(13),
    boxShadow: theme.shadows[3],
  },
  actionGroup: {
    display: "flex",
    width: "330px",
    justifyContent: "space-between",
    marginRight: 0,
  },
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  tripCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },

  fixedHeight: {
    height: 300,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function ReportsSummaryStats() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [label, setLabel] = React.useState({
    c: "$$ 30days",
    t: "Trips 30days",
    o: "Orders 30days",
  });

  const statsObj = useSelector((state) => {
    return state.report.stats;
  });

  React.useEffect(() => {
    dispatch(fetchDashboardStats());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const _Today = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: "$$ Today",
      t: "Trips Today",
      o: "Orders Today",
    }));
    dispatch(fetchDashboardStats(1));
  };

  const _7days = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: "$$ 7days",
      t: "Trips 7days",
      o: "Orders 7days",
    }));
    dispatch(fetchDashboardStats(7));
  };

  const _14days = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: "$$ 14days",
      t: "Trips 14days",
      o: "Orders 14days",
    }));
    dispatch(fetchDashboardStats(14));
  };

  const _30days = () => {
    setLabel((prevState) => ({
      ...prevState,
      c: "$$ 30days",
      t: "Trips 30days",
      o: "Orders 30days",
    }));
    dispatch(fetchDashboardStats(30));
  };

  const options = [
    {
      name: "Today",
      onClick: _Today,
    },
    {
      name: "7 days",
      onClick: _7days,
    },
    {
      name: "14 days",
      onClick: _14days,
    },
    {
      name: "30 days",
      onClick: _30days,
    },
  ];

  if (statsObj.loading) {
    return (
      <div className={classes.summaryCards}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classes.summaryCards}>
      <>
        <SummaryCard
          title={label.c}
          titleWithAction={<VerticonOptions options={options} />}
          value={formatCash(statsObj.commission) ?? 0}
        />
        <SummaryCard title={label.t} value={statsObj.totalTrips ?? 0} />
        <SummaryCard title={label.o} value={statsObj.totalOrders ?? 0} />
      </>
    </div>
  );
}

export default ReportsSummaryStats;
