import React, { useState } from "react";
import {
  fetchAllOrders,
  start_date_end_date_from_iso,
} from "../../../../Redux/Actions/reportActions";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "./historyFilter.css";
import Controls from "../../../Controls";
import SummaryCard from "../../../Base/SummaryCard";
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from "react-overlays";
import { format } from "date-fns";

const statuses = ["all", "Delivered", "assigned"];
const limitArr = [5, 10, 25, 50, 100, 200];

function HistoryFilter() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    order_status: "all",
    limit: 50,
    start_date: new Date(),
    end_date: new Date(),
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;

    const name = target.name;

    setFilter({ ...filter, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const { order_status, limit, start_date, end_date } = filter;

    const [_start_date, _end_date] = start_date_end_date_from_iso(
      start_date,
      end_date
    );

    // fetch orders
    dispatch(
      fetchAllOrders({
        limit,
        _start_date,
        _end_date,
        order_status,
      })
    );
  };

  let order_status_types = statuses.map((item, index) => {
    return (
      <option value={item} key={index}>
        {item}
      </option>
    );
  });

  let limits = limitArr.map((item, index) => {
    return (
      <option value={item} key={index}>
        {item}
      </option>
    );
  });

  return (
    <div
      style={{
        margin: "20px 0 80px 0",
      }}
    >
      <SummaryCard
        title={
          filter.start_date &&
          filter.end_date &&
          `Your Queries for orders from ${format(
            filter.start_date,
            "d MMMM, yyy"
          )} -to-
            ${format(filter.end_date, "d MMM, yyy")}`
        }
        component={
          <section className="filter-container">
            <form className="filter-form" onSubmit={handleSubmit}>
              {/* select order status */}
              <div className="form-group">
                <label htmlFor="order_status">order status</label>
                <select
                  id="order_status"
                  value={filter.order_status}
                  onChange={handleChange}
                  name="order_status"
                  className="form-control"
                >
                  {order_status_types}
                </select>
              </div>

              {/* select limits */}
              <div className="form-group">
                <label htmlFor="order_status">order limits</label>
                <select
                  id="limit"
                  value={filter.limit}
                  onChange={handleChange}
                  name="limit"
                  className="form-control"
                >
                  {limits}
                </select>
              </div>

              {/* start date */}
              <div className="form-group">
                <label htmlFor="start_date">start date</label>
                <DatePicker
                  selected={filter.start_date}
                  onChange={(date) =>
                    setFilter((prevState) => {
                      return { ...prevState, start_date: date };
                    })
                  }
                  startDate={filter.start_date}
                  endDate={filter.end_date}
                  className="form-control"
                  popperPlacement="top-start"
                  placeholderText="Choose a start date"
                  popperContainer={CalendarContainer}
                />
              </div>

              {/* end date */}

              {/* end date */}
              <div className="form-group">
                <label htmlFor="end_date">end date</label>
                <DatePicker
                  selected={filter.end_date}
                  onChange={(date) =>
                    setFilter((prevState) => {
                      return { ...prevState, end_date: date };
                    })
                  }
                  startDate={filter.start_date}
                  endDate={filter.end_date}
                  minDate={filter.start_date}
                  className="form-control"
                  popperPlacement="top-start"
                  placeholderText="Choose an end date"
                  popperContainer={CalendarContainer}
                />
              </div>
              <div className="form-group">
                <label htmlFor="submit-button">Submit Query</label>
                <Controls.Button type="submit" size="small" text="submit" />
              </div>
            </form>
          </section>
        }
      />
    </div>
  );
}

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

export default HistoryFilter;
