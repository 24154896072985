import Chip from "@material-ui/core/Chip";
import { DeletePopUpDialog } from "../../../../../../../Base/DeleteDialog";
import MenuOptions from "../../../../../../../Base/VerticonOptions";
import React from "react";
import {
  cancelOrder,
  cancelOrderMultiple,
} from "../../../../../../../../Redux/Actions/basketActions";
import { useDispatch } from "react-redux";

/*eslint-disable eqeqeq*/
function OrderMenuOptions({ order, root_id, order_id, isMultiple }) {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCancleDialogOpen = (handleVerticonClose) => {
    setOpenDialog(true); // open dialog
    handleVerticonClose(); // close menu
  };

  const handleClose = () => {
    setOpenDialog(false); //close dialogs
  };

  const handleCancelOrder = () => {
    dispatch(cancelOrder(order, order_id));
  };

  const options = [
    {
      name: "cancel Order",
      onClick: handleCancleDialogOpen,
    },
  ];

  return (
    <>
      <MenuOptions options={options} />
      <DeletePopUpDialog
        title={`Cancel Order`}
        onSave={handleCancelOrder}
        message="Cancelling this order deletes it from the datbase, are you sure you want to proceed?"
        open={openDialog}
        handleClose={handleClose}
      />
    </>
  );
}

// cancel cancelchip
export function CancelChip({ order, order_id, isMultiple = false, root_id }) {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false); //close dialogs
  };

  const handleCancelOrder = () => {
    dispatch(cancelOrder(order, order_id));
  };

  const handleCancelOrderMultiple = () => {
    dispatch(cancelOrderMultiple(root_id, order, order_id));
  };

  return (
    <>
      <Chip
        size="large"
        label="Cancel Order"
        onDelete={() => setOpenDialog(true)}
        color="primary"
        variant="outline"
      />
      <DeletePopUpDialog
        title={`Cancel Order`}
        onSave={
          isMultiple == true ? handleCancelOrderMultiple : handleCancelOrder
        }
        message="Cancelling this order deletes it from the database, are you sure you want to proceed?"
        open={openDialog}
        handleClose={handleClose}
      />
    </>
  );
}

export default OrderMenuOptions;
