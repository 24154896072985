import { LOADING_UI, SET_SUCCESS } from "../types";
import axios from "axios";
import { setErrors } from "./uiActions";

export const sendRiderSms =
    ({ rider_name, rider_phone, message }) =>
    (dispatch) => {
        dispatch({ type: LOADING_UI });

        let phone = "";

        if (rider_phone.startsWith("+254")) {
            phone = rider_phone;
        } else {
            phone = "+254" + rider_phone.slice(1);
        }

        const payload = {
            to: [phone.replace(/\s+/g, "")],
            message,
        };

        axios
            .post("/sms", payload)
            .then((res) => {
                dispatch({
                    type: SET_SUCCESS,
                    payload: res.data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch(setErrors(err));
            });
    };

export const sendReceiverSms =
    ({ rider_name, receiver_phone, message }) =>
    (dispatch) => {
        dispatch({ type: LOADING_UI });

        let phone = "";

        if (receiver_phone.startsWith("+254")) {
            phone = receiver_phone;
        } else {
            phone = "+254" + receiver_phone.slice(1);
        }

        const payload = {
            to: [phone.replace(/\s+/g, "")],
            message,
        };

        axios
            .post("/sms", payload)
            .then((res) => {
                dispatch({
                    type: SET_SUCCESS,
                    payload: res.data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch(setErrors(err));
            });
    };
