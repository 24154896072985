import { SET_FREELANCER_STATS, SET_FREELANCER_SKILLS } from "../types";

const initialState = {
    currentFreelancerStats: [],
    currentFreelancerSkills: [],
};

/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq*/
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FREELANCER_STATS:
            return {
                ...state,
                currentFreelancerStats: action.payload,
            };

        case SET_FREELANCER_SKILLS:
            return {
                ...state,
                currentFreelancerSkills: action.payload,
            };

        default:
            return state;
    }
}
