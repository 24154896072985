import Loader from "../../../Base/Loader";
import MaterialTable from "../../../Base/Table/MaterialTable";
import React from "react";
// component
import SummaryCard from "../../../Base/SummaryCard";
import Toolbar from "@material-ui/core/Toolbar";
// redux
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

const columns = [
  {
    field: "id",
    headerName: "ID",
    title: "ID",
    width: 78,
    filtering: false,
  },
  {
    field: "order_id",
    headerName: "Order ID",
    title: "Order ID",
    width: 98,
    filterPlaceholder: "Filter by Order ID",
  },
  {
    field: "delivery_fee",
    headerName: "Delivery Fee",
    title: "Delivery Fee",
    align: "right",
    width: 50,
    filterPlaceholder: "Filter by delivery_fee",
  },
  {
    field: "delivery_schedule",
    headerName: "Delivery Schedule",
    width: 150,
    title: "Delivery Schedule",
    filterPlaceholder: "Filter by id delivery Schedule",
  },
  {
    field: "fee_paid_by",
    headerName: "Fee paid by",
    title: "Fee paid by",
    align: "right",
    width: 150,
    filterPlaceholder: "Filter by phone number",
  },
  // {
  //   field: "assigned_at",
  //   headerName: "Assigned At",
  //   width: 78,
  //   title: "Assigned At",
  //   render: (rowData) =>
  //     format(new Date(rowData.assigned_at), "MMM d, yyy H:m:s"),
  //   filterPlaceholder: "Assigned At",
  // },
  {
    field: "order_date",
    headerName: "Order Date",
    width: 88,
    title: "Order Date",
  },
  {
    field: "order_status",
    headerName: "Order Status",
    width: 88,
    title: "Order Status",
    filterPlaceholder: "Order Status",
  },
  {
    field: "proof_of_delivery",
    headerName: "Proof of Delivery",
    width: 88,
    title: "Proof of Delivery",
  },
  {
    field: "delivery_type",
    headerName: "Delivery Type",
    width: 88,
    title: "Delivery Type",
    filterPlaceholder: "Delivery Type",
  },
  {
    field: "receiver_address",
    headerName: "Receiver Address",
    width: 100,
    title: "Receiver Address",
    filterPlaceholder: "Receiver Address",
  },

  {
    field: "receiver_name",
    headerName: "Receiver Name",
    width: 88,
    title: "Receiver Name",
  },
  {
    field: "receiver_phone",
    headerName: "Receiver Phone",
    width: 88,
    title: "Receiver Phone",
  },
  {
    field: "sender_name",
    headerName: "Sender Name",
    width: 88,
    title: "Sender Name",
  },
  {
    field: "sender_phone",
    headerName: "sender_phone",
    width: 88,
    title: "sender_phone",
  },
  {
    field: "rider_name",
    headerName: "Rider Name",
    width: 108,
    title: "Rider Name",
  },
  {
    field: "rider_phone",
    headerName: "Rider Phone",
    width: 88,
    title: "Rider Phone",
  },
];

function createData(
  id,
  order_id,
  delivery_fee,
  delivery_schedule,
  fee_paid_by,
  assigned_at,
  order_date,
  order_status,
  order_time,
  proof_of_delivery,
  delivery_type,
  receiver_address,
  receiver_name,
  receiver_permanent_address,
  receiver_phone,
  sender_name,
  sender_phone,
  rider_phone,
  rider_name
) {
  return {
    id,
    order_id,
    delivery_fee,
    delivery_schedule,
    fee_paid_by,
    assigned_at,
    order_date,
    order_status,
    order_time,
    proof_of_delivery,
    delivery_type,
    receiver_address,
    receiver_name,
    receiver_permanent_address,
    receiver_phone,
    sender_name,
    sender_phone,
    rider_phone,
    rider_name,
  };
}

function createRows(rows) {
  const arr = [];
  rows.forEach(
    ({
      id,
      order_id,
      delivery_fee,
      delivery_schedule,
      fee_paid_by,
      assigned_at,
      order_date,
      order_status,
      order_time,
      proof_of_delivery,
      delivery_type,
      receiver_address,
      receiver_name,
      receiver_permanent_address,
      receiver_phone,
      sender_name,
      sender_phone,
      rider_phone,
      rider_name,
    }) => {
      arr.push(
        createData(
          id,
          order_id,
          delivery_fee,
          delivery_schedule,
          fee_paid_by,
          assigned_at,
          order_date,
          order_status,
          order_time,
          proof_of_delivery,
          delivery_type,
          receiver_address,
          receiver_name,
          receiver_permanent_address,
          receiver_phone,
          sender_name,
          sender_phone,
          rider_phone,
          rider_name
        )
      );
    }
  );

  return arr;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  grow: {
    flexGrow: 0.6,
    flexShrink: 0.6,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    // padding: theme.spacing(1),
  },
}));

function HistoryTable(props) {
  const classes = useStyles();

  const orders = useSelector((state) => state.report.history.orders);
  const loading = useSelector((state) => state.ui.loading);

  if (!isLoaded(orders)) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  if (isEmpty(orders)) {
    return <SummaryCard title="No orders found" />;
  }

  return (
    <>
      <div className={classes.root}>
        <Toolbar>
          <div edge="start" className={classes.grow} />
        </Toolbar>

        <div className={classes.summaryCard}>
          <MaterialTable
            title="Orders Information"
            columns={columns}
            data={createRows(orders)}
            options={{
              exportFileName: "Orders Informations",
              actionsColumnIndex: -1,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(HistoryTable);
