import { isEmpty, isLoaded } from "react-redux-firebase";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../../../../../../../Base/Loader";
import OrderList from "./OrderList";
import React from "react";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

/* eslint-disable eqeqeq */
function MultipleList() {
  const classes = useStyles();
  const orders = useSelector((state) => {
    console.log(state.firebase.ordered, "kwa mutiple");

    return get(state.firebase.ordered, `AdminDeliveries.multiple_deliveries`);
  });

  if (!isLoaded(orders)) {
    return <Loader />;
  }

  if (isEmpty(orders)) {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="secondary mailbox folder">
          <ListItem button>
            <ListItemText primary="No multiple orders found" />
          </ListItem>
        </List>
      </div>
    );
  }

  return <OrderList orders={orders} listType="multiple" />;
}

export default MultipleList;
