import {format} from "date-fns";
export default function formatDate(someDate) {
    return format(someDate,"MMM Do YY")
}

export function formatCash(someCash) {
    return new Intl.NumberFormat("en-ke", {
        style: "currency",
        currency: "ksh",
    }).format(someCash);
}
