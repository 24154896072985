import Box from "@material-ui/core/Box";
import CommissionsTablePerMerchant from "./CommissionsTablePerMerchant";
import CommissionsTablePerRider from "./CommissionsTableRider";
import Content from "../../../Container/Content";
import Copyright from "../../../Base/Copyright";
import Grid from "@material-ui/core/Grid";
import OrderChart from "./OrderChart";
import Paper from "@material-ui/core/Paper";
import React from "react";
import ReportsSummaryStats from "./ReportsSummaryStats";
import SalesChart from "./SalesChart";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 500,
        paddingBottom: theme.spacing(-0.5),
    },
    balanceCard: {
        height: 200,
    },
}));

function Metric() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    // const balancePaper = clsx(classes.paper, classes.balanceCard);

    return (
        <Content>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ReportsSummaryStats />
                </Grid>
                {/* <Grid item xs={12} md={7} lg={8}>
                    <Paper className={balancePaper}></Paper>
                </Grid>
              
                <Grid item xs={12} md={5} lg={4}>
                    <Paper className={balancePaper}>
                     
                    </Paper>
                </Grid> */}
                {/* Chart */}
                <Grid item xs={12}>
                    <Paper className={fixedHeightPaper}>
                        {/* Chart  */}
                        <OrderChart />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={fixedHeightPaper}>
                        {/* <Tools /> */}
                        <SalesChart />
                    </Paper>
                </Grid>
                {/* ExpensesTable */}
                <Grid item xs={12} md={6}>
                    <Paper className={fixedHeightPaper}>
                        {/* ExpensesTable  */}
                        <CommissionsTablePerMerchant />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper className={fixedHeightPaper}>
                        {/* commissions table rider  */}
                        <CommissionsTablePerRider />
                    </Paper>
                </Grid>
            </Grid>
            <Box pt={4}>
                <Copyright />
            </Box>
        </Content>
    );
}

export default Metric;
