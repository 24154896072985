import { firebaseConnect, isEmpty, isLoaded } from "react-redux-firebase";

import { DeletePopUpDialog as ConfirmDialog } from "../../../Base/DeleteDialog";
import Loader from "../../../Base/Loader";
import MaterialTable from "../../../Base/Table/MaterialTable";
import PropTypes from "prop-types";
import React from "react";
// component
import SummaryCard from "../../../Base/SummaryCard";
import Toolbar from "@material-ui/core/Toolbar";
import { compose } from "redux";
// import Content from "../../../Container/Content";
// redux
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";

const columns = [
  {
    field: "id",
    headerName: "ID",
    title: "ID",
    width: 78,
    filtering: false,
    emptyValue: () => <em>null</em>,
  },
  {
    field: "name",
    headerName: "Full Name",
    title: "Full Name",
    width: 78,
    filterPlaceholder: "Filter by name",
    emptyValue: () => <em>null</em>,
  },
  {
    field: "email",
    headerName: "Email",
    title: "Email",
    width: 150,
    filterPlaceholder: "Filter by email",
    emptyValue: () => <em>null</em>,
  },
  {
    field: "id_number",
    headerName: "ID Number",
    width: 150,
    title: "ID Number",
    filterPlaceholder: "Filter by id number",
    emptyValue: () => <em>null</em>,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    title: "Phone Number",
    width: 150,
    type: "numeric",
    filterPlaceholder: "Filter by phone number",
    emptyValue: () => <em>null</em>,
  },
  {
    field: "is_active",
    headerName: "Account Status",
    width: 78,
    title: "Account Status",
    render: (rowData) => (rowData.is_active ? `Active` : `Suspended`),
    filterPlaceholder: "Account Status",
    emptyValue: () => <em>null</em>,
  },
  {
    field: "kra_pin",
    headerName: "Kra Pin",
    width: 78,
    title: "Kra Pin",
    filterPlaceholder: "Filter by number pin",
    emptyValue: () => <em>null</em>,
  },

  {
    field: "user_id",
    headerName: "User ID",
    width: 200,
    title: "User UUID",
    emptyValue: () => <em>null</em>,
  },
];

function createData(
  idx,
  name,
  profileImage,
  id_number,
  email,
  phone,
  kra_pin,
  is_active,
  key
) {
  return {
    id: idx + 1,
    name,
    profileImage,
    id_number,
    email,
    phone,
    kra_pin,
    is_active,
    key,
    user_id: key,
  };
}

function createRows(rows) {
  const arr = [];
  rows.forEach(
    (
      {
        value: {
          name,
          profileImage,
          id_number,
          email,
          phone,
          kra_pin,
          is_active,
        },
        key,
      },
      index
    ) => {
      arr.push(
        createData(
          index,
          name,
          profileImage,
          id_number,
          email,
          phone,
          kra_pin,
          is_active,
          key
        )
      );
    }
  );

  return arr;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  grow: {
    flexGrow: 0.6,
    flexShrink: 0.6,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },

  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    // padding: theme.spacing(1),
  },
}));

function MerchantsTables(props) {
  const classes = useStyles();

  const { merchants, openInPopup, confirmDialog, setConfirmDialog } = props;

  if (!isLoaded(merchants)) {
    return <Loader />;
  }

  if (isEmpty(merchants)) {
    return <SummaryCard title="No merchants found" />;
  }

  return (
    <>
      <div className={classes.root}>
        <Toolbar>
          <div edge="start" className={classes.grow} />
        </Toolbar>

        <div className={classes.summaryCard}>
          <MaterialTable
            title="Merchants Information"
            columns={columns}
            actions={[
              {
                icon: "visibility",
                tooltip: "View User",
                onClick: (event, rowData) =>
                  openInPopup({
                    key: rowData.key,
                    value: {
                      name: rowData.name,
                      profileImage: rowData.profileImage,
                      id_number: rowData.id_number,
                      email: rowData.email,
                      phone: rowData.phone,
                      kra_pin: rowData.kra_pinnnnnnnnnnnnn,
                      is_active: rowData.is_active,
                    },
                  }),
              },
            ]}
            data={createRows(merchants)}
            options={{
              exportFileName: "Merchants Informations",
              actionsColumnIndex: -1,
            }}
          />
        </div>

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { merchants: state.firebase.ordered["Merchants"] };
};

const mapActionsToProps = {};

MerchantsTables.propTypes = {
  ui: PropTypes.object.isRequired,
  openInPopup: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  firebaseConnect(["Merchants"])
);

export default enhance(MerchantsTables);
