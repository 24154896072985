import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

export default function Title(props) {
    const { color, variant } = props;
    return (
        <Typography
            component="h2"
            variant={variant || "h6"}
            color={color || "primary"}
            gutterBottom
        >
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: PropTypes.node,
};
