import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import { composeWithDevTools } from "redux-devtools-extension";
import fbconfig from "../Utils/fb.config";
import { getFirebase } from "react-redux-firebase";
import localStorage from "redux-persist/lib/storage";
import rootReducer from "./Reducers/rootReducer";
import thunk from "redux-thunk";

const initialState = {};

const persistConfig = {
    key: "root",
    storage: localStorage,
};

const middleware = [thunk.withExtraArgument({ getFirebase })];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export const rrfProps = {
    firebase: fbconfig,
    config: {},
    dispatch: store.dispatch,
};

export const persistor = persistStore(store);

export default store;
