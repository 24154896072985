import { LOAD_STATUS } from "../types";

const initialState = {
    statuses: [],
};

/* eslint-disable import/no-anonymous-default-export */
export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_STATUS:
            return {
                ...state,
                statuses: action.payload,
            };

        default:
            return state;
    }
}
